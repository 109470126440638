import {Checkbox, FormControlLabel, Radio, RadioGroup} from '@mui/material';
import React, {useState} from 'react';
import useTranslations from '../../../hooks/useTranslation';
import AdminTextInput from './AdminTextInput';

const AddUpdateBookComponent = ({bookId, setBookData, bookData}) => {
  const {translate} = useTranslations();

  return (
    <>
      <AdminTextInput labelColor="black" autoComplete="off" labelVariant="body2" colorBlack={'text-black'} value={bookData.book_name} onChange={e => setBookData((prev) => ({...prev, book_name: e.target.value}))} fullWidth  placeholder={translate('Enter Title')}  bottomLabel={translate('Title')} isRequired/>
      <AdminTextInput labelColor="black" autoComplete="off" labelVariant="body2" colorBlack={'text-black'} value={bookData.url} onChange={e => setBookData((prev) => ({...prev, url: e.target.value}))} fullWidth  placeholder={translate('Enter Time to Order URL')}  bottomLabel={translate('Time to Order URL')} isRequired />
      <FormControlLabel
        sx={{
          '& .MuiFormControlLabel-label': {
            color: '#000000 !important',
          }
        }}
        checked={bookData.ignore_weeks}
        onChange={() => setBookData((prev) => ({...prev, ignore_weeks: !bookData.ignore_weeks}))}
        control={
          <Checkbox
            sx={{
              color: '#000000 !important', 
              '&.Mui-checked': {
                color: '#000000 !important'
              }
            }}
          />
        }
        label={translate('Ignore this book when calculating remaining weeks to read.')}
      />

      <RadioGroup
        row
        value={bookData.year}
        sx={{
          marginTop: 2,
          color: '#d32f2f !important',
          '& .MuiFormControlLabel-label': {
            color: '#d32f2f !important',
            fontWeight: '800',
            fontSize: '1rem'
          }
        }}
        onChange={e => setBookData((prev) => ({...prev, year: e.target.value}))}
      >
        <FormControlLabel
          value="D1"
          control={
            <Radio
              sx={{
                color: '#d32f2f !important',
                '&.Mui-checked': {
                  color: '#d32f2f !important'
                }
              }}
            />
          }
          label={translate('D1')}
        />
        <FormControlLabel
          value="D2"
          control={
            <Radio
              sx={{
                color: '#d32f2f !important',
                '&.Mui-checked': {
                  color: '#d32f2f !important'
                }
              }}
            />
          }
          label={translate('D2')}
        />
        <FormControlLabel
          value="D3"
          control={
            <Radio
              sx={{
                color: '#d32f2f !important',
                '&.Mui-checked': {
                  color: '#d32f2f !important'
                }
              }}
            />
          }
          label={translate('D3')}
        />
      </RadioGroup>
    </>
  );
};

export default AddUpdateBookComponent;
