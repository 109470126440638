import React, {useState, useEffect} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {Grid, Box, useTheme} from '@mui/material';
import TextInput from '../../common/TextInput';
import FormWrapper from '../../common/StyledFormWrapper';
import CheckBoxInput from '../../common/CheckBoxInput';
import {Link, useNavigate} from 'react-router-dom';
import Alert from '@mui/material/Alert';
import useDebounce from '../../../hooks/useDebounce';
import {CONSTANTS} from '../../../constants';
import {loginUserApi} from '../../../api';
import {validateEmail} from '../../../utils';
import sdlogo from '../../../images/sdlogo.png';
import useAuthStore from '../../../store/authStore';
import useTranslations from '../../../hooks/useTranslation';

const Login = () => {
  const theme = useTheme();
  const iconColor = theme.palette.icon.primary;
  const {translate} = useTranslations();
  const {loginUser, setRememberEmail, rememberEmail, setOrgToTheme, setTheme, setOrganization} = useAuthStore(state => state);
  const [email, setEmail] = useState(rememberEmail || '');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isRemember, setIsRemember] = useState(rememberEmail ? true : false);
  const [loading, setLoading] = useState(false);
  const debounceEmail = useDebounce(email, CONSTANTS.DEBOUNCE_TIMEOUT);

  const handleLogin = async e => {
    e.preventDefault();
    if (!email || !password) {
      setError(translate(CONSTANTS.MESSAGES.ALL_FIELDS_REQUIRED));
      return;
    }
    if (!validateEmail(email)) {
      return;
    }
    if (password?.length < CONSTANTS.PASSWORD_MAX_LENGTH) {
      setError(translate(CONSTANTS.MESSAGES.INVALID_CRED));
      return;
    }
    try {
      const data = {email: email.toLowerCase().trim(), password};
      setLoading(true);
      setError(null);
      const response = await loginUserApi(data);

      if (response?.data?.status === 200) {
        const data = response?.data?.data;
        loginUser(data);
        const org_to_theme = data?.org_to_theme;
        setOrgToTheme(org_to_theme);
        const orgs = data?.organizations[0]?.id;
        const defaultThemeId = org_to_theme?.filter(org => {
          return org.org_id === orgs;
        })[0].theme_id;
        if (defaultThemeId) {
          setTheme(defaultThemeId);
        }
        if (data?.organizations && data?.organizations[0]) {
          setOrganization(data?.organizations[0]);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const clearError = () => {
    setError(null);
  };

  const setRememberHandler = () => {
    setIsRemember(true);
    if (validateEmail(debounceEmail)) {
      setRememberEmail(debounceEmail);
      localStorage.setItem('savedPassword', password);
      localStorage.setItem('savedEmail', email);
    }
  };

  const resetRememberHandler = () => {
    setIsRemember(false);
    localStorage.removeItem('savedPassword');
    localStorage.removeItem('savedEmail');
  };

  useEffect(() => {
    const savedPassword = localStorage.getItem('savedPassword');
    const savedEmail = localStorage.getItem('savedEmail');
    if (savedPassword) {
      setPassword(savedPassword);
    }
    if (savedEmail) {
      setEmail(savedEmail);
    }
  }, []);

  return (
    <>
      <CssBaseline />
      <Box mt={1} px={3} overflow="auto">
        <img src={sdlogo} width="100" height="100" />
        <FormWrapper
          subHeadingText={{
            text: (
              <>
                {translate('New?')} <span style={{textDecoration: 'underline'}}>{translate('Create an account')}</span>
              </>
            ),
            onClick: () => navigate('/signup')
          }}
          buttonInputs={[{text: translate('Log In'), onClick: handleLogin, type: 'submit'}]}
          headingText={translate('Log In')}
          loading={loading}
        >
          <Grid overflow="hidden" container mt={2}>
            <Grid item xs={12} marginBottom={2}>
              <TextInput
                value={email}
                autoComplete="off"
                autoFocus={true}
                placeholder={translate('Email')}
                label={translate('Email')}
                onChange={e => {
                  setEmail(String(e.target.value).replace(/\s+/g, ''));
                  clearError();
                  resetRememberHandler();
                }}
                isAllowSpecialChar={true}
                error={debounceEmail && !validateEmail(debounceEmail)}
                helperText={debounceEmail && !validateEmail(debounceEmail) ? translate('Please enter a valid email') : ''}
                labelVariant="body2"
              />
            </Grid>
            <Grid item xs={12} marginBottom={2}>
              <div className="relative_container">
                <TextInput
                  value={password}
                  autoComplete="new-password"
                  onChange={e => {
                    setPassword(e.target.value);
                    clearError();
                  }}
                  isAllowSpecialChar={true}
                  placeholder={translate('Password')}
                  type={showPassword ? 'text' : 'password'}
                  label={translate('Password')}
                  labelVariant="body2"
                  // error={debouncePassword && debouncePassword.length < 8}
                  // helperText={debouncePassword && debouncePassword.length < 8 ? "Password length should be mininum 8 characters" : ""}
                />
                {showPassword ? <VisibilityIcon onClick={() => setShowPassword(false)} className="eye_icon" /> : <VisibilityOffIcon onClick={() => setShowPassword(true)} className="eye_icon" />}
              </div>
              <div className="reset-link" color={iconColor}>
                <Link className="navigation-links" to="/forgot-password">
                  {translate('Forgot Password')}
                </Link>
              </div>
            </Grid>
            <Grid item xs={12} sx={{height: '55px'}}>
              {error && <Alert severity="error">{error}</Alert>}
            </Grid>
            <Grid item xs={12}>
              <CheckBoxInput checked={isRemember} onChange={() => (isRemember ? resetRememberHandler() : setRememberHandler())} label={translate('Remember Login')} />
            </Grid>
          </Grid>
        </FormWrapper>
      </Box>
    </>
  );
};

export default Login;
