import React from 'react';
import {Box, Typography} from '@mui/material';
import useTranslations from '../../hooks/useTranslation';
import Modal from './Modal';

const ManuallyInvitedModal = ({bgColor = '#fff', textColor = '#000', isModalOpen, setIsModalOpen, titleColor = '#d32f2f'}) => {
  const {translate} = useTranslations();
  return (
    <Modal buttons={[{name: translate('Cancel'), onClick: () => setIsModalOpen(false)}]} modalbgColor={bgColor} modalTextColor={textColor} modalTitleColor={titleColor} open={isModalOpen} onClose={() => setIsModalOpen(false)} title={translate('Information')}>
      <Box>
        <Typography color={textColor} variant="body1" mb={4}>
          {translate("This disciple is manually activated and won't receive emails of the group's.")}
        </Typography>
      </Box>
    </Modal>
  );
};

export default ManuallyInvitedModal;
