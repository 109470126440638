import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import theme from '../../styles/theme';
import {Typography} from '@mui/material';
import useTranslations from '../../hooks/useTranslation';

const SelectInput = ({value, setValue, label, formWidth = '100%', labelVariant = 'body2', placeholder, optionArray = [], disabled, bottomLabel, bottomLabelColor, sx}) => {
  const {translate} = useTranslations();
  const handleChange = event => {
    setValue(event.target.value);
  };

  return (
    <FormControl sx={{width: formWidth, paddingBlock: theme.spacing(1)}}>
      {label && (
        <Typography textAlign="left" marginBottom={10} variant={labelVariant}>
          {translate(label.toUpperCase())}
        </Typography>
      )}
      <Select 
        variant="outlined" 
        value={value} 
        onChange={handleChange} 
        disabled={disabled}
        MenuProps={{
          PaperProps: {
            sx: {
              backgroundColor: 'white',
            },
          },
        }}
        sx={{
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
            borderWidth: '1px'
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
            borderWidth: '2px'
          },
          ...sx

        }}
      >
        <MenuItem disabled value={0} style={{color: 'GrayText', background: 'white', opacity: 1, display: 'none'}}>
          {translate(placeholder)}
        </MenuItem>
        {optionArray.map((element, index) => {
          return (
            <MenuItem style={{color: 'red', background: 'white'}} key={index} value={element.value}>
              {translate(element.label)}
            </MenuItem>
          );
        })}
      </Select>
      {
        bottomLabel && (
          <Typography textAlign="left" variant={labelVariant} color={bottomLabelColor}>
            {translate(bottomLabel)}
          </Typography>
        )
      }
    </FormControl>
  );
};

export default SelectInput;
