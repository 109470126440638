import React from 'react';
import {Typography, Box, Avatar} from '@mui/material';
import useTranslations from '../../../hooks/useTranslation';
import {BASE_URL, CONSTANTS, FILE_PATH_ORGANIZATION} from '../../../constants';
import useAuthStore from '../../../store/authStore';
import toast from 'react-hot-toast';
import IconContainer from '../../../components/common/IconContainer';
import {editsIcon} from '../../../utils/svg';
import {isValidFile} from '../../../utils';

const LogoCard = () => {
  const {translate} = useTranslations();
  const {token, selectedOrganization, updateOrganizationState} = useAuthStore(state => state);
  const imageChangeHandler = async e => {
    const file = e.target.files[0];
    // Check file size
    if (file.size >= CONSTANTS.MAX_IMAGE_FILE_SIZE) {
      toast.error(translate('File is too large (not more than 10 mb)'), {id: 'toast_message'});
      return;
    }
    // Validate file type
    if (!isValidFile('image', file)) {
      toast.error(translate('Only image file allowed!'), {id: 'toast_message'});
      return;
    }

    const formData = new FormData();
    formData.append('organization', file);
    const requestOptions = {
      method: 'PATCH',
      headers: {
        Authorization: token
      },
      body: formData
    };
    try {
      const res = await fetch(`${BASE_URL}/organization/${selectedOrganization.id}?name=upload`, requestOptions);
      const data = await res.json();
      if (data.status === 200) {
        updateOrganizationState(selectedOrganization.id, data.data, '');
        toast.success(translate(data?.message), {id: 'toast_message'});
      }
    } catch (error) {
      toast.error(translate('Failed to upload the image. Please try again.'), {id: 'toast_message'});
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        padding: '16px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        height: 'fit-content',
        backgroundColor: '#ffffff'
      }}
    >
      <Typography color="#000" variant="h6" display="flex" alignItems="center" justifyContent="space-between">
        {translate('Logo')}
        <label style={{cursor: 'pointer'}} htmlFor="contained-button-file">
          <input onChange={imageChangeHandler} style={{display: 'none'}} accept="image/*" id="contained-button-file" type="file" />
          <IconContainer icon={editsIcon} width={20} height={20} />
        </label>
      </Typography>
      <Box
        sx={{
          display: 'inline-block',
          position: 'relative'
        }}
      >
        <Avatar
          src={`${FILE_PATH_ORGANIZATION}/${selectedOrganization.avatar}`}
          sx={{
            width: 150,
            height: 150
          }}
        />
      </Box>
    </Box>
  );
};

export default LogoCard;
