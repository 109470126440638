import React, {useEffect, useState} from 'react';
import {Box, List, ListItem, ListItemText, ListItemIcon, Typography, useMediaQuery} from '@mui/material';
import AdminStructure from './common/AdminStructure';
import {getDashboardData} from '../../api';
import NoDataFoundMessage from '../../components/common/NoDataFoundMessage';
import Loader from '../../components/common/Loader';
import {Bar} from 'react-chartjs-2';
import {Chart, registerables} from 'chart.js';
import useTranslations from '../../hooks/useTranslation';
import {doubleRoundPersonIcon, multiPersonIcon, singleRoundPersonIcon} from '../../utils/svg';
import IconContainer from '../../components/common/IconContainer';
import useAuthStore from '../../store/authStore';
import {Navigate, useNavigate} from 'react-router-dom';
Chart.register(...registerables);

function Dashboard({isAdmin = false, isSuperAdmin = false}) {
  const {translate} = useTranslations();
  const {selectedOrganization} = useAuthStore(state => state);
  const [dashboardData, setDashboardData] = useState({
    phaseData: {},
    progressionData: {},
    quickLookData: {},
    yearStats: {}
  });
  const [loadingPage, setLoadingPage] = useState(false);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const getDashboardDataHandler = async () => {
    try {
      setLoadingPage(true);
      const orgId = isSuperAdmin ? selectedOrganization?.id || 'all' : selectedOrganization?.id;
      const response = await getDashboardData(orgId);
      if (response?.data?.status === 200) {
        setDashboardData(response?.data?.data);
      }
      setLoadingPage(false);
    } catch (err) {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getDashboardDataHandler();
  }, [selectedOrganization]);

  const years = dashboardData?.progressionData?.Years || [];
  const groupsData = [];
  const disciplesData = [];
  const successData = [];

  years.forEach(year => {
    groupsData.push(dashboardData?.progressionData['Groups'][year] || 0);
    disciplesData.push(dashboardData?.progressionData['Disciples'][year] || 0);
    successData.push(dashboardData?.progressionData['Success'][year] || 0);
  });

  const chartData = {
    labels: years,
    datasets: [
      {
        label: translate('Groups'),
        backgroundColor: '#171716',
        borderColor: '#171716',
        borderWidth: 1,
        hoverBackgroundColor: '#171716',
        hoverBorderColor: '#171716',
        data: groupsData
      },
      {
        label: translate('Disciples'),
        backgroundColor: '#9e0001',
        borderColor: '#9e0001',
        borderWidth: 1,
        hoverBackgroundColor: '#9e0001',
        hoverBorderColor: '#9e0001',
        data: disciplesData
      },
      {
        label: translate('Success'),
        backgroundColor: '#cecece',
        borderColor: '#cecece',
        borderWidth: 1,
        hoverBackgroundColor: '#cecece',
        hoverBorderColor: '#cecece',
        data: successData
      }
    ]
  };

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            stepSize: 10
          }
        }
      ]
    }
  };
  function customRound(num) {
    return Math.floor(num + 0.5);
  }
  return (
    <div
      style={{
        backgroundImage: 'none !important',
        backgroundColor: '#f9f9f9',
        height: '100vh',
        width: '100vw',
        overflowY: isSmallScreen ? 'auto' : 'hidden'
      }}
    >
      <AdminStructure heading={translate('Dashboard')}>
        {loadingPage ? (
          <Loader color="#9e0001" />
        ) : !dashboardData ? (
          <NoDataFoundMessage mt={5} message={translate('No data available for this organization')} />
        ) : (
          <Box display={'flex'} justifyContent={'space-between'} overflow={'auto'} px={2} gap={2}>
            <Box display="flex" flexDirection="column" width="56%" gap={2}>
              {/* Quick Look */}
              {dashboardData && (
                <Box bgcolor="#FFF" borderRadius="12px" padding={2}>
                  <Typography variant="h5" gutterBottom color="#000" textAlign="left" px={2}>
                    {translate('Quick Look')}
                  </Typography>
                  <List>
                    <ListItem onClick={() => navigate(!isAdmin ? '' : '/admin-dashboard/groups')} style={{cursor: 'pointer'}}>
                      <ListItemIcon>
                        <IconContainer color="#d32f2f" icon={multiPersonIcon} width={50} height={50} marginRight={8} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        <Typography color="black" fontWeight={'bold'} variant={'h5'}>
                          {dashboardData.quickLookData['active_groups']} {translate('Active Groups')}
                        </Typography>
                        <Typography color="#d32f2f" variant="body1">
                          {dashboardData.quickLookData['lifetime_groups']} {translate('Lifetime Groups')} {' • '}
                          {dashboardData.quickLookData['completed_groups']} {translate('Finished Groups')}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem onClick={() => navigate(!isAdmin ? '' : '/admin-dashboard/disciples')} style={{cursor: 'pointer'}}>
                      <ListItemIcon>
                        <IconContainer color="#d32f2f" icon={singleRoundPersonIcon} width={50} height={50} marginRight={8} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        <Typography color="black" fontWeight={'bold'} variant={'h5'}>
                          {dashboardData.quickLookData['active_disciples']} {translate('Disciples in Process')}
                        </Typography>
                        <Typography color="#d32f2f" variant="body1">
                          {dashboardData.quickLookData['lifetime_disciples']} {translate('Lifetime Disciples')} {' • '}
                          {dashboardData.quickLookData['completed_disciples']} {translate('Disciples Finished')}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem onClick={() => navigate(!isAdmin ? '' : '/admin-dashboard/leaders')} style={{cursor: 'pointer'}}>
                      <ListItemIcon>
                        <IconContainer color="#d32f2f" icon={doubleRoundPersonIcon} width={50} height={50} marginRight={8} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        <Typography color="black" fontWeight={'bold'} variant={'h5'}>
                          {dashboardData.quickLookData['active_leaders']} {translate('Active Leaders')}
                        </Typography>
                        <Typography color="#d32f2f" variant="body1">
                          {dashboardData.quickLookData['lifetime_leaders']} {translate('Lifetime Leaders')} {' • '}
                          {dashboardData.quickLookData['inactive_leaders']} {translate('Inactive Leaders')}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Box>
              )}

              {/* Progression */}
              {dashboardData && (
                <Box bgcolor="#FFF" borderRadius="12px" padding={2}>
                  <Typography gutterBottom variant="h5" color="#000" textAlign="left" px={2}>
                    {translate('Progression by Year')}
                  </Typography>
                  <Box height="240px" width={isSmallScreen ? 'auto' : '100%'}>
                    <Bar data={chartData} options={chartOptions} />
                  </Box>
                </Box>
              )}
            </Box>
            <Box display="flex" flexDirection="column" width="44%" gap={2}>
              {/* Process Stats */}
              {dashboardData && dashboardData.yearStats && (
                <Box bgcolor="#FFF" borderRadius="12px" padding={2}>
                  <Typography gutterBottom variant="h5" color="#000" textAlign="left" px={2} mb={3}>
                    {translate('Current Year Stats')}
                  </Typography>
                  <ul style={{padding: '0 20px'}}>
                    <li style={{display: 'flex', justifyContent: 'space-between', marginBottom: '8px'}}>
                      <Typography width="80%" textAlign="left" variant="body1" color="#000">
                        {translate('Total Starts')}
                      </Typography>
                      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '20%'}}>
                        <Typography variant="body1" color="#000000">
                          {dashboardData.yearStats['total_starts']}
                        </Typography>
                        <Typography visibility="hidden" variant="body1" color="#000000">
                          0.
                        </Typography>
                      </div>
                    </li>
                    <li style={{display: 'flex', justifyContent: 'space-between', marginBottom: '8px'}}>
                      <Typography width="80%" textAlign="left" variant="body1" color="#000">
                        {translate('Total Disciples Made')}
                      </Typography>
                      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '20%'}}>
                        <Typography variant="body1" color="#000000">
                          {dashboardData.yearStats['disciple_made'] || 0}
                        </Typography>
                        <Typography variant="body1" color="#d32f2f">
                          {customRound(Number((dashboardData.yearStats.disciple_made_percent ?? 0).toFixed(2)))}%
                        </Typography>
                      </div>
                    </li>
                    <li style={{display: 'flex', justifyContent: 'space-between', marginBottom: '8px'}}>
                      <Typography width="80%" textAlign="left" variant="body1" color="#000">
                        {translate('Currently Stalled')}
                      </Typography>
                      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '20%'}}>
                        <Typography variant="body1" color="#000000">
                          {dashboardData.yearStats['stalled'] || 0}
                        </Typography>
                        <Typography variant="body1" color="#d32f2f">
                          {customRound(Number((dashboardData.yearStats.stalled_percent ?? 0).toFixed(2)))}%
                        </Typography>
                      </div>
                    </li>
                    <li style={{display: 'flex', justifyContent: 'space-between', marginBottom: '8px'}}>
                      <Typography width="80%" textAlign="left" variant="body1" color="#000">
                        {translate('Currently Dropped')}
                      </Typography>
                      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '20%'}}>
                        <Typography variant="body1" color="#000000">
                          {dashboardData.yearStats['dropped'] || 0}
                        </Typography>
                        <Typography variant="body1" color="#d32f2f">
                          {customRound(Number((dashboardData.yearStats.dropped_percent ?? 0).toFixed(2)))}%
                        </Typography>
                      </div>
                    </li>
                    <li style={{display: 'flex', justifyContent: 'space-between', marginBottom: '8px'}}>
                      <Typography width="80%" textAlign="left" variant="body1" color="#000">
                        {translate('Disciples Making Disciples')}
                      </Typography>
                      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '20%'}}>
                        <Typography variant="body1" color="#000000">
                          {dashboardData.yearStats['active'] || 0}
                        </Typography>
                        <Typography variant="body1" color="#d32f2f">
                          {customRound(Number((dashboardData.yearStats.active_percent ?? 0).toFixed(2)))}%
                        </Typography>
                      </div>
                    </li>
                  </ul>
                </Box>
              )}
              {/* Phase Stats */}
              {dashboardData && (
                <Box bgcolor="#FFF" borderRadius="12px" padding={2} height="45%">
                  <Typography gutterBottom variant="h5" color="#000" textAlign="left" px={2} mb={2}>
                    {translate('Phase Stats')}
                  </Typography>
                  <List>
                    <ListItem dense>
                      <ListItemText
                        sx={{
                          '& .MuiListItemText-primary': {
                            color: '#000'
                          },
                          marginBottom: -2, // Adjust as needed
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                        primary={translate('In D1')}
                        secondary={dashboardData.phaseData['D1_complete'] + dashboardData.phaseData['D1']}
                      />
                    </ListItem>
                    <ListItem dense sx={{marginBottom: '10px'}}>
                      <ListItemText
                        sx={{
                          '& .MuiListItemText-primary': {
                            color: '#000'
                          },
                          // marginBottom: 3,
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                        primary={translate('Finished D1')}
                        secondary={dashboardData.phaseData['D1_complete']}
                      />
                    </ListItem>
                    <ListItem dense>
                      <ListItemText
                        sx={{
                          '& .MuiListItemText-primary': {
                            color: '#000'
                          },
                          marginBottom: -2,
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                        primary={translate('In D2')}
                        secondary={dashboardData.phaseData['D2_complete'] + dashboardData.phaseData['D2']}
                      />
                    </ListItem>
                    <ListItem dense sx={{marginBottom: '10px'}}>
                      <ListItemText
                        sx={{
                          '& .MuiListItemText-primary': {
                            color: '#000'
                          },
                          // marginBottom: 10,
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                        primary={translate('Finished D2')}
                        secondary={dashboardData.phaseData['D2_complete']}
                      />
                    </ListItem>
                    <ListItem dense>
                      <ListItemText
                        sx={{
                          '& .MuiListItemText-primary': {
                            color: '#000'
                          },
                          marginBottom: -2,
                          // marginTop: 3,
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                        primary={translate('In D3')}
                        secondary={dashboardData.phaseData['D3_complete'] + dashboardData.phaseData['D3']}
                      />
                    </ListItem>
                    <ListItem dense>
                      <ListItemText
                        sx={{
                          '& .MuiListItemText-primary': {
                            color: '#000'
                          },
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                        primary={translate('Finished D3')}
                        secondary={dashboardData.phaseData['D3_complete']}
                      />
                    </ListItem>
                  </List>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </AdminStructure>
    </div>
  );
}

export default Dashboard;
