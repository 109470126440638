import React, {useState} from 'react';
import {Box, Typography, Avatar, Select, MenuItem, Menu, IconButton, ListItem, ListItemButton, ListItemText, useTheme} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useAuthStore from '../../../store/authStore';
import {CONSTANTS, FILE_PATH_PROFILE_URL} from '../../../constants';
import {getKeyByValue} from '../../../utils';
import {addUserRoleApi} from '../../../api';
import useTranslations from '../../../hooks/useTranslation';
import IconContainer from '../../../components/common/IconContainer';
import {exitIcon, singlePersonIcon, changePasswordIcon, searchIcon, switchAccountIcon, deletesIcon} from '../../../utils/svg';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

import {useNavigate} from 'react-router-dom';
import useAdminStore from '../../../store/adminStore';
import useLeaderStore from '../../../store/leaderStore';
import Modal from '../../../components/common/Modal';

const DashboardHeader = ({heading, handleAddClick, isSearch, searchHandler, isAddingUser, isAddingGroup, isAddingDisciple, isAddingOrganisation}) => {
  const theme = useTheme();
  const iconColor = theme.palette.icon.primary;
  const navigate = useNavigate();
  const {translate} = useTranslations();
  const {user, loginNewUser, setOrganization, setTheme, org_to_theme, selectedOrganization, logoutUser} = useAuthStore(state => state);
  const [anchorEl, setAnchorEl] = useState(null);
  const rolesArray = user?.user_permissions ? user?.user_permissions?.split(',') : [];
  const userRole = rolesArray?.map(role => {
    const roles = getKeyByValue(CONSTANTS.USER_ROLES, role);
    return {
      label: roles,
      value: roles
    };
  });
  const {clearAdminData} = useAdminStore(state => state);
  const {clearLeaderData} = useLeaderStore(state => state);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState(user?.user_role || '');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const isAdmin = rolesArray?.includes('1');
  const organizations = user?.organizations;

  const handleOrganizationChange = event => {
    const orgId = event.target.value;
    const organization = user.organizations.find(org => org.id === orgId);
    setOrganization(organization);
    const selectedOrg = org_to_theme.find(org => org.org_id == orgId);
    //For Theme
    //const newThemeId = selectedOrg?.theme_id;

    // if (newThemeId) {
    //   setTheme(newThemeId);
    window.location.reload();
    // }
  };

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsDropdownOpen(false);
  };

  const permissionChangeHandler = async permission => {
    setSelectedRole(permission);
    try {
      const roleData = {
        permission: permission,
        is_role_change: true,
        orgId: selectedOrganization.id
      };
      const res = await addUserRoleApi(roleData);
      if (res?.data?.status === 200) {
        loginNewUser({...res?.data?.data, user_role: permission});
      }
    } catch (error) {}
  };

  const toggleDropdown = event => {
    event.stopPropagation();
    setIsDropdownOpen(prevState => !prevState);
  };

  const logoutUserHandler = () => {
    logoutUser();
    clearLeaderData();
    clearAdminData();
  };

  const handleDeleteUser = async () => {
    try {
      setLoading(true);
      const res = await deleteUser(user?.id);
      if (res?.data?.status === 200) {
        setModalOpen(false);
        logoutUserHandler();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        flexGrow={1}
        justifyContent="space-between"
        alignItems="center"
        p={2}
        bgcolor="#f9f9f9"
        sx={{
          display: {
            xs: 'flex',
            sm: 'flex',
            md: 'flex',
            lg: 'flex',
            xl: 'flex'
          },
          marginTop: {
            xs: '30px',
            sm: '30px',
            md: 0,
            lg: 0
          }
        }}
      >
        <Box width="60%" display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography
              variant="h4"
              sx={{
                color: '#000 !important',
                fontWeight: 600,
                fontSize: {
                  xs: '18px',
                  sm: '18px',
                  md: '18px'
                }
              }}
            >
              {heading}
            </Typography>
            <Box ml={4}>
              {isAddingUser && (
                <Box
                  sx={{
                    display: {md: 'flex', xs: 'none', sm: 'none'}
                  }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <IconButton
                    onClick={handleAddClick}
                    sx={{
                      '&:hover': {
                        borderRadius: '12px',
                        height: '30px'
                      }
                    }}
                  >
                    <AddIcon
                      sx={{
                        color: '#D0373B',
                        fontSize: '20px'
                        // padding: 1,
                      }}
                    />{' '}
                    <Typography variant="h6" fontSize="14.4px" color="black">
                      {translate('Add Leaders')}
                    </Typography>
                  </IconButton>
                </Box>
              )}
              {isAddingGroup && (
                <Box
                  sx={{
                    display: {md: 'flex', xs: 'none', sm: 'none'}
                  }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <IconButton
                    onClick={handleAddClick}
                    sx={{
                      '&:hover': {
                        borderRadius: '12px',
                        height: '30px'
                      }
                    }}
                  >
                    <AddIcon
                      sx={{
                        color: '#D0373B',
                        fontSize: '20px'
                        // padding: 1,
                      }}
                    />{' '}
                    <Typography variant="h6" fontSize="14.4px" color="black">
                      {translate('Add Group')}
                    </Typography>
                  </IconButton>
                </Box>
              )}
              {isAddingDisciple && (
                <Box
                  sx={{
                    display: {md: 'flex', xs: 'none', sm: 'none'}
                  }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <IconButton
                    onClick={handleAddClick}
                    sx={{
                      '&:hover': {
                        borderRadius: '12px',
                        height: '30px'
                      }
                    }}
                  >
                    <AddIcon
                      sx={{
                        color: '#D0373B',
                        fontSize: '20px'
                        // padding: 1,
                      }}
                    />{' '}
                    <Typography variant="h6" fontSize="14.4px" color="black">
                      {translate('Add Disciple')}
                    </Typography>
                  </IconButton>
                </Box>
              )}
              {isAddingOrganisation && (
                <Box
                  sx={{
                    display: {md: 'flex', xs: 'none', sm: 'none'}
                  }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <IconButton onClick={handleAddClick}>
                    <AddIcon
                      sx={{
                        color: '#D0373B',
                        fontSize: '20px'
                        // padding: 1,
                      }}
                    />{' '}
                    <Typography variant="h6" fontSize="14.4px" color="black">
                      {translate('Add Organization')}
                    </Typography>
                  </IconButton>
                </Box>
              )}
            </Box>
          </Box>
          <Box display="flex" alignItems="center" justifyContent={'space-between'} px={4} height={'30px'}>
            {isAdmin && (
              <Select
                value={selectedOrganization.id}
                onChange={handleOrganizationChange}
                displayEmpty
                inputProps={{'aria-label': 'Without label'}}
                IconComponent={ExpandMoreIcon}
                sx={{
                  marginRight: 4,
                  color: 'gray !important',
                  outline: 'none',
                  borderRadius: '30px',
                  display: 'flex',
                  height: '100%',
                  alignItems: 'center',
                  width: '220px',
                  bgcolor: 'white !important',
                  '& .MuiSelect-select': {
                    padding: '8px 8px',
                    color: 'gray !important'
                  },
                  '& .MuiSvgIcon-root': {
                    color: '#D0373B'
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none'
                  },
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'white'
                  }
                }}
              >
                {organizations?.map(org => (
                  <MenuItem
                    key={org.id}
                    value={org.id}
                    sx={{
                      color: 'gray !important',
                      textAlign: 'center',
                      backgroundColor: 'white !important',
                      '&:hover': {
                        backgroundColor: '#f9f9f9'
                      }
                    }}
                  >
                    {org.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center" justifyContent={'space-between'} px={4} height={'30px'}>
            {isSearch && (
              <Box
                sx={{
                  bgcolor: 'white',
                  borderRadius: '30px',
                  padding: '0px 20px',
                  display: {
                    md: 'flex',
                    sm: 'none',
                    xs: 'none'
                  },
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '30px'
                }}
              >
                <IconContainer color={'#D0373B'} icon={searchIcon} width={19} height={19} marginRight={8} />
                <input style={{height: '100%', outline: 'none', border: 'none'}} placeholder={translate('Search Here')} onChange={e => searchHandler(e.target.value)} type="text" name="search" id="search" />
              </Box>
            )}
          </Box>
          <Box display="flex" flexDirection="row">
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Typography variant="body1" mr={2} color="#000" fontWeight={500}>
                {user?.first_name || ''} {user?.last_name || ''}
              </Typography>
              <Typography variant="subtitle2" mr={2} color="#000">
                {translate('Administrator')}
              </Typography>
            </Box>
            <Avatar src={`${FILE_PATH_PROFILE_URL}/${user?.avatar}`} onClick={handleMenuOpen} sx={{cursor: 'pointer'}} />
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            sx={{
              '& .MuiMenuItem-root': {
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                borderRadius: '12px'
              },
              '& .MuiMenu-list': {
                padding: 0
              }
            }}
            PaperProps={{
              sx: {
                maxHeight: 'none',
                height: user?.user_role === 'ADMIN' ? '182px' : 'fit-content',
                backgroundColor: '#ffffff',
                borderRadius: '15px'
              }
            }}
          >
            <>
              {user?.user_role === 'ADMIN' && (
                <>
                  <ListItem disablePadding>
                    <ListItemButton
                      sx={{
                        backgroundColor: '#ffffff',
                        color: '#000000',
                        '&:hover': {
                          backgroundColor: '#ffffff'
                        },
                        fontSize: '1.2rem'
                      }}
                      onClick={() => navigate('/admin-dashboard/profile')}
                    >
                      <IconContainer icon={singlePersonIcon} width={20} height={20} marginLeft={'-6px'} marginRight={'10px'} />
                      <ListItemText
                        primary={translate('Profile')}
                        primaryTypographyProps={{
                          sx: {
                            color: '#000000',
                            fontWeight: '600'
                          }
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                  {/* Comment for now */}
                  {/* <ListItem disablePadding>
                      <ListItemButton
                        sx={{
                          backgroundColor: '#ffffff',
                          color: '#000000',
                          '&:hover': {
                          backgroundColor: '#ffffff'
                          },
                          fontSize: '1.2rem'
                        }}
                        // onClick={() => navigate('/edit-profile')}
                      >
                        <WorkspacesIcon
                          sx={{
                            marginLeft: '-4px',
                            marginRight: '10px'
                          }}
                        />
                        <ListItemText
                          primary={translate('Journey')}
                          primaryTypographyProps={{
                            sx: {
                              color: '#000000',
                              fontWeight: '600'
                            }
                          }}
                        />
                      </ListItemButton>
                    </ListItem> */}

                  <ListItem disablePadding>
                    <ListItemButton
                      sx={{
                        backgroundColor: '#ffffff',
                        color: '#000000',
                        '&:hover': {
                          backgroundColor: '#ffffff'
                        },
                        fontSize: '1.2rem'
                      }}
                      onClick={() => navigate('/admin-dashboard/change-password')}
                    >
                      <IconContainer icon={changePasswordIcon} width={20} height={20} marginLeft={'-6px'} marginRight={'10px'} />
                      <ListItemText
                        primary={translate('Change Password')}
                        primaryTypographyProps={{
                          sx: {
                            color: '#000000',
                            fontWeight: '600'
                          }
                        }}
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      sx={{
                        backgroundColor: '#ffffff',
                        color: '#000000',
                        '&:hover': {
                          backgroundColor: '#ffffff'
                        },
                        fontSize: '1.2rem'
                      }}
                      onClick={() => setModalOpen(true)}
                    >
                      <IconContainer icon={deletesIcon} width={20} height={20} marginLeft={'-6px'} marginRight={'10px'} />
                      <ListItemText
                        primary={translate('Delete My Account')}
                        primaryTypographyProps={{
                          sx: {
                            color: '#000000',
                            fontWeight: '600'
                          }
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                  {userRole?.length > 1 && (
                    <ListItem disablePadding sx={{display: 'flex', minWidth: '100%', flexDirection: 'column', alignItems: 'flex-start'}}>
                      <ListItemButton
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          backgroundColor: '#ffffff',
                          color: '#000000',
                          '&:hover': {
                            backgroundColor: '#ffffff'
                          },
                          fontSize: '1.2rem',
                          minWidth: '100%'
                        }}
                        onClick={toggleDropdown}
                      >
                        <Box display="flex" alignItems="center">
                          <IconContainer icon={switchAccountIcon} width={20} height={20} marginLeft={'-6px'} marginRight={'10px'} />
                          <ListItemText
                            primary={translate('Switch Accounts')}
                            primaryTypographyProps={{
                              sx: {
                                color: '#000000',
                                fontWeight: '600'
                              }
                            }}
                          />
                        </Box>
                        <ArrowForwardIosRoundedIcon style={{fontSize: '15px', marginLeft: '3px'}} />
                      </ListItemButton>
                      {isDropdownOpen && (
                        <Box width="100%">
                          {userRole.map(role => (
                            <ListItem
                              key={role.value}
                              onClick={() => {
                                permissionChangeHandler(role.value);
                                setIsDropdownOpen(false);
                              }}
                              sx={{
                                cursor: 'pointer',
                                color: '#000000',
                                maxHeight: '30px',
                                width: '100%',
                                backgroundColor: role.label === selectedRole ? '#D32F2F' : '#ffffff'
                              }}
                            >
                              <ListItemText
                                primary={translate(role.label)}
                                primaryTypographyProps={{
                                  sx: {
                                    color: role.label === selectedRole ? '#ffffff' : '#000000'
                                  }
                                }}
                              />
                            </ListItem>
                          ))}
                        </Box>
                      )}
                    </ListItem>
                  )}
                </>
              )}

              <ListItem disablePadding>
                <ListItemButton
                  sx={{
                    backgroundColor: '#ffffff',
                    color: '#000000',
                    '&:hover': {
                      backgroundColor: '#ffffff'
                    },
                    fontSize: '1.2rem'
                  }}
                  onClick={() => logoutUserHandler()}
                >
                  <IconContainer icon={exitIcon} width={20} height={20} marginLeft={'-4px'} marginRight={'10px'} />
                  <ListItemText
                    primary={translate('Log Out')}
                    primaryTypographyProps={{
                      sx: {
                        color: '#000000',
                        fontWeight: '600'
                      }
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </>
          </Menu>
        </Box>
      </Box>
      <Modal
        open={modalOpen}
        setOpen={setModalOpen}
        title={translate('Delete Account')}
        modalbgColor={'#ffffff'}
        modalTextColor={'#000000'}
        modalTitleColor={'#d32f2f'}
        buttons={[
          {name: translate('Close'), onClick: () => setModalOpen(false), buttonBgColor: '#ffffff', buttonTextColor: 'black', buttonHoverColor: '#f5f5f5', type: 'outlined'},
          {name: translate('Delete'), onClick: () => handleDeleteUser(), loading: loading, buttonBgColor: '#d32f2f', buttonTextColor: '#ffffff', buttonHoverColor: '#b71c1c'}
        ]}
      >
        <Typography sx={{color: 'black'}}>{translate('Are you sure you want to delete your account? All associated data will be removed from the application soon, and you will be logged out immediately.')}</Typography>
      </Modal>
    </>
  );
};

export default DashboardHeader;
