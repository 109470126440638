import React, {useEffect, useState} from 'react';
import {Box, CircularProgress, Typography, styled} from '@mui/material';
import useTranslations from '../../../hooks/useTranslation';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import DeleteReadingPlanComponent from './DeleteReadingPlanComponent';
import EditDuplicateReadingPlanComponent from './EditDuplicateReadingPlanComponent';
import AddReadingPlanComponent from './AddReadingPlanComponent';
import Modal from '../../../components/common/Modal';
import Loader from '../../../components/common/Loader';
import NoDataFoundMessage from '../../../components/common/NoDataFoundMessage';
import {addBookInReadingPlanHandler, addPlanApi, deletePlanApi, getAllPublishedBooks, getAllReadingPlansApi, getAllReadingPlansBooks, updateReadingPlansApi} from '../../../api';
import useAuthStore from '../../../store/authStore';
import TextInput from '../../../components/common/TextInput';
import {deletesIcon, duplicateIcon, editsIcon} from '../../../utils/svg';
import IconContainer from '../../../components/common/IconContainer';

const StyledMenuProps = {
  PaperProps: {
    sx: {
      backgroundColor: '#9e0101', // Set background color for the dropdown
      color: 'white'
    }
  }
};

const StyledMenuItem = styled(MenuItem)(({theme}) => ({
  backgroundColor: '#9e0101',
  color: 'white',
  '&:hover': {
    backgroundColor: '#d32f2f'
  },
  '&.Mui-selected': {
    backgroundColor: '#c9141d !important',
    color: 'white'
  }
}));

const ReadingPlansTabComponent = ({role}) => {
  const {translate} = useTranslations();
  const {selectedOrganization} = useAuthStore();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [allSDPlans, setAllSDPlans] = useState([]);
  const [allCustomPlans, setAllCustomPlan] = useState([]);
  const [allSDBooks, setAllSDBook] = useState({
    name: '',
    level: '',
    plan_id: '',
    books: [],
    ignore_weeks: true
  });
  const [bookList, setBookList] = useState([]);
  const weeks = Array.from({length: 48}, (_, i) => i + 1);
  const [planLoader, setPlanLoader] = useState(false);
  const [booksLoader, setBooksLoader] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const [modifyingPlan, setModifyingPlan] = useState({
    name: '',
    meeting_weeks: '',
    level: ''
  });
  const [isChecked, setIsChecked] = useState(false);
  const [addBooksLoader, setAddBooksLoader] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [remainingWeeks, setRemainingWeeks] = useState(0);
  const [readingPlanInfo, setReadingPlanInfo] = useState({});
  const getAllPublishedBooksHandler = async () => {
    try {
      const orgId = role === 'ADMIN' ? selectedOrganization.id : '';
      const response = await getAllPublishedBooks(orgId);
      if (response.data.status === 200) {
        setBookList(response.data.data?.libraryBooks || []);
      }
    } catch (error) {}
  };

  const handleAddBook = index => {
    const newBooks = [...allSDBooks.books];
    newBooks.splice(index + 1, 0, {book_id: '', start_week: 1, end_week: 2, weeks_to_read: 1, ignore_weeks: true});
    setAllSDBook({...allSDBooks, books: newBooks});
  };
  const handleDeleteBook = index => {
    const newBooks = allSDBooks.books.filter((_, i) => i !== index);
    setAllSDBook({...allSDBooks, books: newBooks});
  };
  const handleBookChange = (index, field, value) => {
    const newBooks = [...allSDBooks.books];
    newBooks[index][field] = value;
    if (field === 'book_id') {
      const findedBook = bookList?.find(item => {
        if (role === 'ADMIN') {
          return item?.book_id === value;
        } else {
          return item?.id === value;
        }
      });
      newBooks[index]['ignore_weeks'] = findedBook?.ignore_weeks == 0 ? false : true;
    }
    if (field === 'start_week' || field === 'weeks_to_read') {
      const startWeek = newBooks[index].start_week || 0;
      const weeksToRead = newBooks[index].weeks_to_read || 0;
      newBooks[index].end_week = startWeek + weeksToRead;
    }
    setAllSDBook({...allSDBooks, books: newBooks});
  };

  const handleCheckPlan = async (e, plan) => {
    try {
      const data = {
        is_public: e.target.checked,
        ...(role === 'ADMIN' && {
          organization_id: selectedOrganization.id
        })
      };
      setAllCustomPlan(prevState => prevState.map(item => (item.id === plan.id ? {...item, is_public: e.target.checked} : item)));
      const res = await updateReadingPlansApi(plan.id, data, true);
      if (res.data.status === 200) {
        if (res?.data?.data?.is_in_use) {
          setModalOpen(true);
          setModalType('message');
        }
      }
      getAllSDPlanHandler();
      getAllPublishedBooksHandler();
    } catch (error) {}
  };

  const getAllSDPlanBooksHandler = async plan => {
    try {
      setSelectedPlan(plan.id);
      setBooksLoader(true);
      const res = await getAllReadingPlansBooks(plan.id);
      if (res.data?.status === 200) {
        setAllSDBook({name: plan.name, level: plan.level, plan_id: plan.id, books: res.data?.data?.data || []});
        setReadingPlanInfo(res?.data?.data?.readingPlanInfo);
      }
      setBooksLoader(false);
    } catch (error) {
      setBooksLoader(false);
    }
  };

  const getAllSDPlanHandler = async () => {
    try {
      setPlanLoader(true);
      const orgId = role === 'ADMIN' ? selectedOrganization.id : '';
      const res = await getAllReadingPlansApi(orgId);
      if (res.data.status === 200) {
        setAllSDPlans(res.data.data?.sdStandardPlans);
        setAllCustomPlan(res.data.data?.customPlans);
        if (res?.data?.data?.sdStandardPlans?.length > 0) {
          if (!selectedPlan) {
            getAllSDPlanBooksHandler(res?.data?.data?.sdStandardPlans?.[0]);
          }
        }
      }
      setPlanLoader(false);
    } catch (error) {
      setPlanLoader(false);
    }
  };

  const handleModalOpener = (type, plan) => {
    setModalOpen(true);
    setModalType(type);
    if (type === 'duplicate' || type === 'edit' || type === 'delete') {
      setModifyingPlan(plan);
    }
  };

  const handleChangeInputs = e => {
    setModifyingPlan(prev => ({...prev, [e.target.name]: e.target.value}));
  };

  const handleSubmit = async () => {
    try {
      setModalLoader(true);
      let res;
      if (modalType === 'delete') {
        res = await deletePlanApi(modifyingPlan.id);
        if (res.data.status === 200) {
          if (res?.data?.data?.is_in_use) {
            setModalOpen(true);
            setModalType('message');
          }
        }
      } else if (modalType === 'edit') {
        const editingData = {
          name: modifyingPlan.name,
          level: modifyingPlan.level,
          meeting_weeks: modifyingPlan.meeting_weeks
        };
        res = await updateReadingPlansApi(modifyingPlan.id, editingData, '', true);
      } else if (modalType === 'duplicate') {
        const editingData = {
          name: modifyingPlan.name,
          level: modifyingPlan.level,
          meeting_weeks: modifyingPlan.meeting_weeks,
          reading_plan_id: modifyingPlan.id,
          ...(role === 'ADMIN' && {
            organization_id: selectedOrganization.id
          })
        };
        res = await addPlanApi(editingData, 'true');
      } else {
        const editingData = {
          name: modifyingPlan.name,
          level: modifyingPlan.level,
          meeting_weeks: modifyingPlan.meeting_weeks,
          ...(role === 'ADMIN' && {
            organization_id: selectedOrganization.id
          })
        };
        res = await addPlanApi(editingData);
      }
      if (!res?.data?.data?.is_in_use && res.data?.status === 200) {
        getAllSDPlanHandler();
        setModalOpen(false);
        setModifyingPlan({
          name: '',
          meeting_weeks: '',
          level: ''
        });
      }
      setIsChecked(false);
      setModalLoader(false);
    } catch (error) {
      setModalLoader(false);
    }
  };

  const handleSaveEdits = async () => {
    const data = {
      plan_id: allSDBooks.plan_id,
      books: allSDBooks.books?.map((book2, i) => {
        return {
          book_id: book2.book_id,
          end_week: Number(book2.start_week) + Number(book2.weeks_to_read),
          start_week: book2.start_week,
          weeks_to_read: book2.weeks_to_read,
          sequence_number: i + 1
        };
      })
    };
    try {
      setAddBooksLoader(true);
      await addBookInReadingPlanHandler(data);
      setAddBooksLoader(false);
    } catch (error) {
      setAddBooksLoader(false);
    }
  };

  useEffect(() => {
    if (allSDBooks.books.length === 0) {
      setAllSDBook({...allSDBooks, books: [{title: '', start_week: 1, end_week: 2, weeks_to_read: 1, ignore_weeks: true}]});
    }
  }, [allSDBooks.books]);

  useEffect(() => {
    getAllPublishedBooksHandler();
    getAllSDPlanHandler();
  }, []);

  useEffect(() => {
    let remainingWeeks = readingPlanInfo?.meeting_weeks;
    allSDBooks.books.forEach(book => {
      if (!book.ignore_weeks) {
        remainingWeeks = remainingWeeks - book.weeks_to_read;
      }
    });
    setRemainingWeeks(remainingWeeks);
  }, [allSDBooks]);

  const SaveEdits = () => {
    return (
      <Box
        onClick={handleSaveEdits}
        width={'25%'}
        margin={'20px auto'}
        sx={{
          bgcolor: '#000000',
          color: 'white',
          px: 3,
          py: 1,
          borderRadius: 12,
          cursor: 'pointer'
        }}
      >
        {addBooksLoader ? <CircularProgress sx={{color: 'white'}} size={12} /> : translate('Save Edits')}
      </Box>
    );
  };
  return (
    <Box height={'80vh'}>
      <Box display={'flex'} justifyContent={'space-between'} gap={4} height={'100%'}>
        {/* Left Panel - Plan Details */}
        <Box width={'45%'} height={'100%'} bgcolor={'#9e0101'} borderRadius={2} overflow="hidden">
          <Typography variant="h5" fontWeight={'bold'} textAlign={'left'} m={2} mb={0.5} color="white">
            {allSDBooks.name} ({allSDBooks.level})
          </Typography>
          <Typography mx={2} my={0} textAlign={'left'} color="white">
            {translate('Remaining Weeks for Reading: ')}
            {isNaN(remainingWeeks) || Number(remainingWeeks) < 0 ? 0 : remainingWeeks}
          </Typography>
          <Typography mx={2} fontSize={'12px'} textAlign={'left'} color="white" fontStyle={'italic'}>
            {translate('The remaining weeks of reading is your Total Weeks of Discipleship less all weeks allocated for reading below.')}
          </Typography>
          <hr style={{border: '0.5px solid #FFFFFF', width: '95%', margin: '2px auto'}} />
          <Box my={1} height={!((!readingPlanInfo?.is_sd_plan && role === 'ADMIN') || role === 'SUPERADMIN') ? '64vh' : '52vh'} overflow="auto">
            <Box display={'flex'} mb={1} justifyContent={'space-between'} alignItems={'center'} mx={7} mr={17}>
              <Typography color={'#FFFFFF'} variant="h6">
                {translate('Books')}
              </Typography>
              <Box>
                <Typography>{translate('Weeks')}</Typography>
                <Typography>{translate('to Read')}</Typography>
              </Box>
            </Box>
            {booksLoader ? (
              <Loader color="white" />
            ) : (
              allSDBooks.books.map((book, index) => (
                <Box key={index} mx={2} color="white" mb={1}>
                  <Box display="flex" alignItems="center" gap={2}>
                    <Box width={'5%'}>
                      <Typography>{index + 1}.</Typography>
                    </Box>
                    <Box width={'75%'} display={'flex'} gap={2} alignItems={'center'}>
                      <Select
                        disabled={role === 'ADMIN' && readingPlanInfo?.is_sd_plan ? true : false}
                        value={book.book_id}
                        onChange={e => handleBookChange(index, 'book_id', e.target.value)}
                        MenuProps={StyledMenuProps}
                        sx={{
                          color: 'white',
                          width: '76%',
                          height: '35px',
                          borderRadius: '6px !important',
                          '& .MuiSelect-icon': {color: 'white'}
                        }}
                      >
                        {bookList.map(book => (
                          <StyledMenuItem sx={{backgroundColor: '#9e0101', zIndex: 999}} key={book?.id} value={role === 'ADMIN' ? book?.book_id : book.id}>
                            {book?.book_name}
                          </StyledMenuItem>
                        ))}
                      </Select>
                      <Select
                        disabled={role === 'ADMIN' && readingPlanInfo?.is_sd_plan ? true : false}
                        value={book.weeks_to_read}
                        onChange={e => handleBookChange(index, 'weeks_to_read', e.target.value)}
                        MenuProps={StyledMenuProps}
                        sx={{
                          color: 'white',
                          width: '20%',
                          height: '35px',
                          borderRadius: '6px !important',
                          '& .MuiSelect-icon': {color: 'white'}
                        }}
                      >
                        {weeks.map(week => (
                          <StyledMenuItem sx={{backgroundColor: '#9e0101', zIndex: 999}} key={week} value={week}>
                            {week}
                          </StyledMenuItem>
                        ))}
                      </Select>
                    </Box>
                    <Box width={'20%'} display="flex" alignItems="center" gap={2}>
                      <IconButton disabled={role === 'ADMIN' && readingPlanInfo?.is_sd_plan ? true : false} onClick={() => handleDeleteBook(index)} sx={{color: 'white'}}>
                        <IconContainer icon={deletesIcon} width={20} height={20} color={role === 'ADMIN' && readingPlanInfo?.is_sd_plan ? '#8A8A8A' : 'white'} />
                      </IconButton>
                      <IconButton disabled={role === 'ADMIN' && readingPlanInfo?.is_sd_plan ? true : false} onClick={() => handleAddBook(index)} sx={{color: 'white'}}>
                        <AddIcon
                          sx={{
                            color: role === 'ADMIN' && readingPlanInfo?.is_sd_plan ? '#8A8A8A' : 'white'
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box mt={1}>
                    <Box display={'flex'} gap={2} alignItems={'center'}>
                      <Box width={'5%'} height={'100%'}></Box>
                      <Box width={'75%'} display={'flex'} gap={2} justifyContent={'space-between'} alignItems={'center'}>
                        <Box width="50%" display={'flex'} gap={1} alignItems={'center'}>
                          <Select
                            disabled={role === 'ADMIN' && readingPlanInfo?.is_sd_plan ? true : false}
                            value={book.start_week}
                            onChange={e => handleBookChange(index, 'start_week', e.target.value)}
                            MenuProps={StyledMenuProps}
                            sx={{
                              color: 'white',
                              width: '70px',
                              height: '35px',
                              borderRadius: '6px !important',
                              '& .MuiSelect-icon': {color: 'white'}
                            }}
                          >
                            {weeks.map(week => (
                              <StyledMenuItem
                                sx={{
                                  backgroundColor: '#9e0101',
                                  zIndex: 999
                                }}
                                key={week}
                                value={week}
                              >
                                {week}
                              </StyledMenuItem>
                            ))}
                          </Select>
                          <Typography>{translate('Start Week')}</Typography>
                        </Box>
                        <Box width="50%" display={'flex'} gap={1} alignItems={'center'}>
                          <Typography>{translate('Ends on Week')}</Typography>
                          <TextInput width="45%" mb={0} style={{height: '16px ', width: '100%', borderRadius: '6px'}} disabled value={book.end_week} type={'text'} labelVariant="body2" />
                        </Box>
                      </Box>
                      <Box width={'20%'} height={'100%'}></Box>
                    </Box>
                  </Box>
                </Box>
              ))
            )}
          </Box>
          {!readingPlanInfo?.is_sd_plan && role === 'ADMIN' && <hr style={{height: '1px', backgroundColor: 'white', width: '100%'}} />}
          {role === 'SUPERADMIN' && <hr style={{height: '1px', backgroundColor: 'white', width: '100%'}} />}
          {!readingPlanInfo?.is_sd_plan && role === 'ADMIN' && <SaveEdits />}
          {role === 'SUPERADMIN' && <SaveEdits />}
        </Box>

        {/* Right Panel - Plans List */}
        <Box width={'55%'} height={'100%'} display={'flex'} flexDirection={'column'} gap={2}>
          <Box border={'1px solid #9e0101'} borderRadius={2} height={role === 'SUPERADMIN' ? '100%' : '60%'} sx={{overflowY: 'auto'}}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={2}>
              <Typography color={'#000000'} variant="h5" fontWeight={'bold'} textAlign={'left'}>
                {translate('SD Standard Plans')}
              </Typography>
              {role === 'SUPERADMIN' && (
                <Box
                  onClick={() => handleModalOpener('add')}
                  sx={{
                    color: '#9e0101',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}
                >
                  <AddIcon /> {translate('Add Plan')}
                </Box>
              )}
            </Box>
            <Box>
              {planLoader ? (
                <Loader color="#9e0101" />
              ) : allSDPlans?.length === 0 ? (
                <NoDataFoundMessage message={translate('No Plans Available')} />
              ) : (
                allSDPlans.map((plan, index) => (
                  <Box
                    px={2}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {bgcolor: '#f1c5c6'},
                      bgcolor: selectedPlan === plan.id ? '#f1c5c6' : 'transparent'
                    }}
                    key={index}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box width="80%" display="flex" alignItems="center" gap={1}>
                      <input
                        type="checkbox"
                        defaultChecked={role === 'ADMIN' ? (plan?.organizationIds?.split(',').includes(String(selectedOrganization.id)) ? true : false) : plan.is_public}
                        onChange={e => {
                          handleCheckPlan(e, plan);
                        }}
                        style={{
                          width: '20px',
                          height: '20px'
                        }}
                      />
                      <Typography
                        onClick={() => {
                          getAllSDPlanBooksHandler(plan);
                        }}
                        width="100%"
                        textAlign="left"
                        color={'#000000'}
                        variant="h6"
                      >
                        {plan.name} ({plan.level})
                      </Typography>
                    </Box>
                    <Box display="flex" gap={2}>
                      {role === 'SUPERADMIN' && <IconContainer icon={editsIcon} width={20} height={20} onClick={() => handleModalOpener('edit', plan)} />}
                      <IconContainer icon={duplicateIcon} width={20} height={20} onClick={() => handleModalOpener('duplicate', plan)} />
                      {role === 'SUPERADMIN' && (
                        <IconContainer
                          icon={deletesIcon}
                          width={20}
                          height={20}
                          onClick={() => {
                            handleModalOpener('delete', plan);
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                ))
              )}
            </Box>
          </Box>
          {role === 'ADMIN' && (
            <Box border={'1px solid #9e0101'} borderRadius={2} height={'40%'} sx={{overflowY: 'auto'}}>
              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={2}>
                <Typography color={'#000000'} variant="h5" fontWeight={'bold'} textAlign={'left'}>
                  {translate('Our Custom Plans')}
                </Typography>
                {role === 'ADMIN' && (
                  <Box
                    onClick={() => handleModalOpener('add')}
                    sx={{
                      color: '#9e0101',
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer'
                    }}
                  >
                    <AddIcon /> {translate('Add Plan')}
                  </Box>
                )}
              </Box>
              <Box>
                {planLoader ? (
                  <Loader color="#9e0101" />
                ) : allCustomPlans?.length === 0 ? (
                  <NoDataFoundMessage message={translate('No Plans Available')} />
                ) : (
                  allCustomPlans.map((plan, index) => (
                    <Box
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {bgcolor: '#f1c5c6'},
                        bgcolor: selectedPlan === plan.id ? '#f1c5c6' : 'transparent'
                      }}
                      px={2}
                      key={index}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box width="80%" display="flex" alignItems="center" gap={1}>
                        <input
                          type="checkbox"
                          checked={plan.is_public}
                          onChange={e => {
                            handleCheckPlan(e, plan, '');
                          }}
                          style={{
                            width: '20px',
                            height: '20px'
                          }}
                        />
                        <Typography
                          width="100%"
                          textAlign="left"
                          onClick={() => {
                            getAllSDPlanBooksHandler(plan);
                          }}
                          color={'#000000'}
                          variant="h6"
                        >
                          {plan.name} ({plan.level})
                        </Typography>
                      </Box>
                      <Box display="flex" gap={2}>
                        <IconContainer icon={editsIcon} width={20} height={20} onClick={() => handleModalOpener('edit', plan)} />
                        <IconContainer icon={duplicateIcon} width={20} height={20} onClick={() => handleModalOpener('duplicate', plan)} />
                        <IconContainer
                          icon={deletesIcon}
                          width={20}
                          height={20}
                          onClick={() => {
                            handleModalOpener('delete', plan);
                          }}
                        />
                      </Box>
                    </Box>
                  ))
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Modal
        open={modalOpen}
        setOpen={setModalOpen}
        title={translate(modalType === 'add' ? (role === 'SUPERADMIN' ? translate('Add SD Reading Plan') : translate('Add Custom Plan')) : modalType === 'edit' ? translate('Edit Reading Plan') : modalType === 'duplicate' ? translate('Duplicate Reading Plan') : translate('Delete Reading Plan'))}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        buttons={[
          {
            name: translate('Cancel'),
            onClick: () => {
              setModalOpen(false);
              setModifyingPlan({
                name: '',
                meeting_weeks: '',
                level: ''
              });
              if (modalType === 'message') {
                getAllPublishedBooksHandler();
                getAllSDPlanHandler();
              }
            },
            buttonBgColor: '#ffffff',
            buttonTextColor: 'black',
            buttonHoverColor: '#f5f5f5',
            type: 'outlined'
          },
          {
            name: modalType === 'delete' ? translate('Delete') : translate('Save'),
            buttonBgColor: '#d32f2f',
            buttonTextColor: '#ffffff',
            buttonHoverColor: '#b71c1c',
            disabled: modalType === 'delete' ? !isChecked : !modifyingPlan.name || !modifyingPlan.meeting_weeks || !modifyingPlan.level ? true : false,
            loading: modalLoader,
            hide: modalType === 'message',
            onClick: () => handleSubmit()
          }
        ]}
      >
        {modalType === 'add' && <AddReadingPlanComponent plan={modifyingPlan} handleChange={handleChangeInputs} />}
        {modalType === 'delete' && <DeleteReadingPlanComponent isChecked={isChecked} setIsChecked={setIsChecked} />}
        {modalType === 'edit' && <EditDuplicateReadingPlanComponent plan={modifyingPlan} handleChange={handleChangeInputs} />}
        {modalType === 'duplicate' && <EditDuplicateReadingPlanComponent plan={modifyingPlan} handleChange={handleChangeInputs} />}
        {modalType === 'message' && <p>{translate("Currently, this plan is being used in some of the organization's groups for outside reading. Please reach out to the group leaders to update the plan of the group from outside reading.")}</p>}
      </Modal>
    </Box>
  );
};

export default ReadingPlansTabComponent;
