import React, {useEffect, useState} from 'react';
import {Typography, IconButton, Box, FormControl} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import useTranslations from '../../../hooks/useTranslation';
import StyledSelectInput from './StyledSelectInput';
import AdminTextInput from './AdminTextInput';
import Modal from '../../../components/common/Modal';
import {updateOrganization} from '../../../api';
import useAuthStore from '../../../store/authStore';
import {CONSTANTS} from '../../../constants';
import { editsIcon } from '../../../utils/svg';
import IconContainer from '../../../components/common/IconContainer';

const OrganizationStatsCard = ({data, reloadData}) => {
  const {translate, switchLanguage} = useTranslations();
  const {selectedOrganization, updateOrgDefaultLanguage} = useAuthStore(state => state);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    denomination: '',
    leadership_structure: '',
    style: '',
    year_founded: '',
    default_language: ''
  });
  const [buttonLoader, setButtonLoader] = useState(false);

  const resetData = () => {
    if (data) {
      setFormData({
        denomination: data.denomination || '',
        leadership_structure: data.leadership_structure || '',
        style: data.style || '',
        year_founded: data.year_founded || '',
        default_language: data.default_language || ''
      });
    }
  };

  const handleSubmitClick = async () => {
    try {
      setButtonLoader(true);
      const res = await updateOrganization(selectedOrganization.id, formData, 'stats');
      if (res?.data?.status === 200) {
        setIsEditing(false);
        updateOrgDefaultLanguage({default_language: formData.default_language});
        switchLanguage(Number(formData.default_language));
        reloadData();
      }
      setButtonLoader(false);
    } catch (error) {
      setButtonLoader(false);
    }
  };

  useEffect(() => {
    resetData();
  }, [data]);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          padding: '16px',
          borderRadius: '8px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#ffffff'
        }}
      >
        <Typography
          variant="h6"
          mb={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: '#000'
          }}
        >
          {translate('Organization Stats')}
          <IconButton onClick={() => setIsEditing(true)} size="small" aria-label="edit">
            <IconContainer icon={editsIcon} width={20} height={20} />
          </IconButton>
        </Typography>

        {[
          {label: translate('Active Membership'), value: data?.active_membership || 0},
          {label: translate('Denomination'), value: data?.denomination},
          {label: translate('Leadership Structure'), value: data?.leadership_structure},
          {label: translate('Style'), value: data?.style},
          {label: translate('Year Founded'), value: data?.year_founded},
          {label: translate('Language'), value: CONSTANTS.LANG[data?.default_language]?.name}
        ].map((item, index) => (
          <Typography variant="body1" sx={{color: '#000', display: 'flex', textAlign: 'start', justifyContent: 'space-between'}} key={index}>
            <span>{item?.label || ''}</span>
            <span>{item?.value || ''}</span>
          </Typography>
        ))}
      </Box>

      <Modal
        open={isEditing}
        onClose={() => {
          setIsEditing(false);
          resetData();
        }}
        title={translate('Edit Organization Stats')}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        buttons={[
          {
            name: translate('Cancel'),
            onClick: () => {
              setIsEditing(false);
              resetData();
            },
            buttonBgColor: '#ffffff',
            buttonTextColor: 'black',
            buttonHoverColor: '#f5f5f5',
            type: 'outlined'
          },
          {
            name: translate('Save'),
            buttonBgColor: '#d32f2f',
            buttonTextColor: '#ffffff',
            buttonHoverColor: '#b71c1c',
            onClick: handleSubmitClick,
            loading: buttonLoader,
            disabled: buttonLoader || !(formData.denomination && formData.leadership_structure && formData.style && formData.year_founded.trim())
          }
        ]}
      >
        <StyledSelectInput value={formData?.denomination} onChange={e => setFormData(prev => ({...prev, denomination: e.target.value}))} variant="outlined" fullWidth labelTopGap={-2.5} bottomLabel={translate('Denomination')} isRequired optionArray={data?.datalist?.DENOMINATION || []} />
        <StyledSelectInput value={formData?.leadership_structure} onChange={e => setFormData(prev => ({...prev, leadership_structure: e.target.value}))} variant="outlined" fullWidth labelTopGap={-2.5} bottomLabel={translate('Leadership Structure')} isRequired optionArray={data?.datalist?.LEADERSHIP_STRUCTURE || []} />
        <StyledSelectInput value={formData.style} onChange={e => setFormData(prev => ({...prev, style: e.target.value}))} variant="outlined" fullWidth labelTopGap={-2.5} bottomLabel={translate('Style')} isRequired optionArray={data?.datalist?.STYLE || []} />
        <FormControl fullWidth sx={{marginTop: '8px'}}>
          <AdminTextInput type="number" bottomLabel={translate('Year Founded')}  placeholder={translate('Enter Year Founded')}  labelVariant="body2" value={formData.year_founded} onChange={e => setFormData(prev => ({...prev, year_founded: e.target.value}))} isRequired />
        </FormControl>
        <StyledSelectInput value={formData?.default_language} onChange={e => setFormData(prev => ({...prev, default_language: e.target.value}))} variant="outlined" fullWidth labelTopGap={-2.5} bottomLabel={translate('Preferred Language')} isRequired optionArray={data?.datalist?.LANGUAGES || []} />
      </Modal>
    </>
  );
};

export default OrganizationStatsCard;
