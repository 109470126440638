import React from 'react';
import theme from '../../styles/theme';
import {Box, TextField, Typography} from '@mui/material';
import {CONSTANTS} from '../../constants';
import {isSpecialCharacterIncludes} from '../../utils';

const TextInput = ({label, error, helperText, labelColor, disabled, labelVariant, labelBottomGap, onChange, isAllowSpecialChar = false, maxLength, readOnly = false, colorBlack, paddingBlock, bottomLabel, mb = 2, height = 'auto', width = '100%', style = {}, type = 'text', ...rest}) => {
  return (
    <Box display="flex" flexDirection="column" mb={mb} width={width} height={height}>
      {label && (
        <Typography textAlign="left" color={labelColor} variant={labelVariant} mb={labelBottomGap}>
          {label.toUpperCase()}
        </Typography>
      )}
      <TextField
        error={error ? true : false}
        helperText={helperText}
        autoComplete="off"
        disabled={disabled}
        sx={{paddingBlock: paddingBlock ? paddingBlock : 0}}
        InputProps={{
          theme: theme
        }}
        type={type}
        inputProps={{
          maxLength: maxLength ? maxLength : CONSTANTS.TEXT_FIELD_MAX_LENGTH,
          readOnly: readOnly,
          className: colorBlack ? colorBlack : '',
          style: style
        }}
        onChange={e => (isAllowSpecialChar ? onChange(e) : !isSpecialCharacterIncludes(e.target.value) && onChange(e))}
        {...rest}
      />
      {bottomLabel && (
        <Typography textAlign="left" color={labelColor} variant={labelVariant} mb={labelBottomGap}>
          {bottomLabel}
        </Typography>
      )}
    </Box>
  );
};

export default TextInput;
