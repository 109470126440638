import {Box, Typography} from '@mui/material';
import React from 'react';

const NoDataFoundMessage = ({mt, message, color="textSecondary"}) => {
  return (
    <Box mt={mt}>
      <Typography variant="h6" align="center" color={color}>
        {message}
      </Typography>
    </Box>
  );
};

export default NoDataFoundMessage;
