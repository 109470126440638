import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {IconButton, Typography} from '@mui/material';
import {Box, fontSize} from '@mui/system';
import {useState} from 'react';
import Modal from './Modal';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import 'moment/locale/en-gb';
import moment from 'moment';
import {CONSTANTS} from '../../constants';
import HelpOutline from '@mui/icons-material/HelpOutline';
import useTranslations from '../../hooks/useTranslation';

const DatePicker = ({onClose, setOpen, open, minDate, disabled, value, onChange, error, showDaysOutsideCurrentMonth, shouldDisableDate, disablePast, tooltip, smallDatePickerClass, maxDate, views, format, newStyle, rest, className="customDatePicker", fontSize}) => {
  const {translate} = useTranslations();
  const [modalOpen, setModalOpen] = useState(false);

  const handleIconClick = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: smallDatePickerClass ? 'space-around' : 'flex-start', ...(newStyle && newStyle)}}>
      {tooltip && (
        <IconButton onClick={handleIconClick} style={{marginLeft:'8px',marginRight:'8px', width: '28px', height: '28px'}}>
            <HelpOutline style={{color: 'white'}} />
          </IconButton>
      )}

      <Modal open={modalOpen} setOpen={setModalOpen} title={translate("What does this move look like?")} buttons={[{name: translate("Close"), onClick: () => handleModalClose()}]}>
        <Typography sx={{color: 'white'}}>{tooltip}</Typography>
      </Modal>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en">
        <DemoContainer sx={{textAlign: 'left', overflow: 'hidden', paddingTop: 0}} components={['MobileDatePicker']}>
          <MobileDatePicker
            className={`${className} ${smallDatePickerClass}`}
            open={open}
            sx={{
              "& .MuiInputBase-input": {
                // color: "",
                fontSize: fontSize ?? "14px !important"
              }
            }}
            views={['year', 'month', 'day']}
            slotProps={{
              inputAdornment: {
                position: 'start'
              },
              textField: {
                onClick: () => setOpen()
              },
              field: {
                readOnly: true,
              },
              layout: {
                sx: {
                  '.MuiPickersDay-root': {
                    color: '#000000 !important',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#000000',
                      color: '#FFFFFF !important',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                      },
                    },
                    '&.Mui-disabled': {
                      color: '#B0B0B0 !important',
                    },
                  },
                  '.MuiPickersYear-yearButton': {
                    color: '#000000',
                    '&.Mui-selected': {
                      backgroundColor: 'primary.main',
                    },
                  },
                  '.MuiPickersMonth-monthButton': {
                    color: '#000000',
                    '&.Mui-selected': {
                      backgroundColor: 'primary.main',
                    },
                  },
                }
              }
            }}
            onClose={() => onClose()}
            value={moment(value)}
            format={format ? format : CONSTANTS.DATE_FORMAT}
            onChange={onChange}
            error={error}
            {...(views && {views})}
            {...(minDate && {minDate})}
            {...(maxDate && {maxDate})}
            {...(disabled && {disabled})}
            {...(showDaysOutsideCurrentMonth && {
              showDaysOutsideCurrentMonth
            })}
            {...(shouldDisableDate && {shouldDisableDate})}
            {...(disablePast && {disablePast})}
            {...rest}
          />
        </DemoContainer>
      </LocalizationProvider>
    </Box>
  );
};

export default DatePicker;
