import React, {useState} from 'react';
import {Box, useMediaQuery} from '@mui/material';
import AdminStructure from './common/AdminStructure';
import useTranslations from '../../hooks/useTranslation';
import TabSwitcher from './common/TabSwitcher';
import ReadingPlansTabComponent from './components/ReadingPlansTabComponent';
import AvailableBooksTabComponent from './components/AvailabelBooksTabComponent';

function ReadingPlans({role}) {
  const {translate} = useTranslations();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedTab2, setSelectedTab2] = useState('All Books');
  const heading = role === 'SUPERADMIN' ? translate('SD Standardized Outside Reading Plans') : translate('Organization Outside Reading Plans');
  return (
    <div
      style={{
        backgroundImage: 'none !important',
        backgroundColor: '#f9f9f9',
        height: '100vh',
        width: '100vw',
        overflowY: isSmallScreen ? 'auto' : 'hidden'
      }}
    >
      <AdminStructure heading={heading}>
        <Box px={2}>
          <TabSwitcher
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
            tab1Text={translate('Reading Plans')}
            tab2Text={translate('Available Books')}
            switcher={[
              {title: 'D1', onClick: () => setSelectedTab2('D1')},
              {title: 'D2', onClick: () => setSelectedTab2('D2')},
              {title: 'D3', onClick: () => setSelectedTab2('D3')},
              {title: 'All Books', onClick: () => setSelectedTab2('All Books')}
            ]}
            selectedTab2={selectedTab2}
          >
            {selectedTab === 0 && <ReadingPlansTabComponent role={role} />}
            {selectedTab === 1 && <AvailableBooksTabComponent role={role} selectedTab2={selectedTab2} />}
          </TabSwitcher>
        </Box>
      </AdminStructure>
    </div>
  );
}

export default ReadingPlans;
