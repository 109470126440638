import React, {useEffect, useState} from 'react';
import FormWrapper from '../components/common/StyledFormWrapper';
import TextInput from '../components/common/TextInput';
import {Avatar, IconButton, Box, Typography, Grid, Alert} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {getUsersDetails} from '../api';
import UserContainer from '../components/common/UserContainer';
import {BASE_URL, CONSTANTS, FILE_PATH_PROFILE_URL} from '../constants';
import {toast} from 'react-hot-toast';
import {capitalizeWords, isValidFile, validatePhone} from '../utils';
import HeaderContainer from '../components/common/HeaderBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import useAuthStore from '../store/authStore';
import Loader from '../components/common/Loader';
import DatePicker from '../components/common/DatePicker';
import moment from 'moment';
import useTranslations from '../hooks/useTranslation';

const EditProfile = () => {
  const {translate} = useTranslations();
  const [error, setError] = useState(null);
  const [dateOpen, setDateOpen] = useState(false);
  const [userData, setUserData] = useState({
    id: '',
    name: '',
    avatar: '',
    mobile_number: '',
    birthday: ''
  });
  const [editUserData, setEditUserData] = useState({
    id: '',
    name: '',
    avatar: '',
    mobile_number: '',
    birthday: ''
  });
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [profileData, setProfileData] = useState();
  const {user, token} = useAuthStore(state => state);
  const navigate = useNavigate();
  const userId = user?.id;

  const handleInputChange = e => {
    setError(null);
    if (e.target.value.length <= 64) {
      setEditUserData(prevData => ({
        ...prevData,
        name: capitalizeWords(e.target.value)
      }));
    }
  };

  const handlePhoneInputChange = e => {
    setError(null);
    const phoneNumber = e.target.value;
    if (!validatePhone(phoneNumber)) {
      return;
    }
    setEditUserData(prevData => ({
      ...prevData,
      mobile_number: phoneNumber
    }));
  };
  const imageChangeHandler = e => {
    let file = e.target.files[0];
    if (file.size >= CONSTANTS.MAX_IMAGE_FILE_SIZE) {
      toast.error(translate('File is too large (not more than 10 mb)'));
      return;
    }
    if (isValidFile('image', file)) {
      setEditUserData(prevData => ({
        ...prevData,
        avatar: e.target.files[0]
      }));
    } else {
      toast.error(translate('Only image file allowed!'), {
        id: 'toast_message'
      });
    }
  };

  const handleUpdate = async () => {
    const {name, mobile_number, birthday} = editUserData;
    if (!name || name.trim().length === 0 || !birthday) {
      setError(translate('All fields are required!'));
      return;
    }
    if (mobile_number && !validatePhone(mobile_number)) {
      setError(translate('Please enter valid phone number!'));
      return;
    }
    try {
      setButtonLoading(true);
      const formData = new FormData();
      formData.append('first_name', name.trim().split(' ')[0] || '');
      formData.append('last_name', name.trim().split(' ')[1] || '');
      formData.append('mobile_number', mobile_number || '');
      formData.append('birthday', moment(birthday).format('YYYY-MM-DD') || '');
      if (editUserData?.avatar && typeof editUserData?.avatar !== 'string') {
        formData.append('profile_avatar', editUserData?.avatar);
      }

      const requestOptions = {
        method: 'PATCH',
        headers: {
          Authorization: token
        },
        body: formData
      };

      const response = await fetch(`${BASE_URL}/users/1`, requestOptions);
      const data = await response.json();
      if (data?.status === 200) {
        toast.success(translate('User updated successfully!'), {
          id: 'toast_message'
        });
        navigate(-1);
      } else {
        toast.error(translate('Something went wrong!'), {
          id: 'error_message'
        });
      }
      setButtonLoading(false);
    } catch (error) {
      setButtonLoading(false);
    }
  };

  const getUserHandler = async () => {
    try {
      setLoading(true);
      const currentUserData = await getUsersDetails(userId);
      const response = currentUserData?.data;
      setProfileData(response?.data);
      if (response?.status === 200) {
        setUserData({
          id: response?.data?.id,
          name: response?.data?.name,
          avatar: response?.data?.avatar,
          mobile_number: response?.data?.mobile_number,
          birthday: response?.data?.birthday
        });
        setEditUserData({
          id: response?.data?.id,
          name: `${response?.data?.first_name || ''} ${response?.data?.last_name || ''}`.trim(),
          avatar: response?.data?.avatar,
          mobile_number: response?.data?.mobile_number,
          birthday: response?.data?.birthday
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserHandler();
  }, []);

  return (
    <UserContainer onClick={() => navigate('/create-group')} filePath={userData?.avatar ? `${FILE_PATH_PROFILE_URL}/${userData?.avatar}` : ''} isGroup={false} userData={userData} isShow={false} isShow2={false}>
      <HeaderContainer my={3} mx={3}>
        <ArrowBackIcon style={{cursor: 'pointer', color: 'white'}} onClick={() => navigate(-1)} />
      </HeaderContainer>
      <Box mt={1} px={3} overflow="auto">
        {loading ? (
          <Loader />
        ) : (
          <>
            <FormWrapper buttonInputs={[{text: translate('Update'), onClick: handleUpdate}]} headingText={translate('Edit Profile')} loading={buttonLoading}>
              <IconButton>
                <label style={{display: 'flex', cursor: 'pointer'}} htmlFor="contained-button-file">
                  <div style={{position: 'relative'}}>
                    <Avatar
                      alt={editUserData?.name}
                      src={editUserData?.avatar ? (typeof editUserData?.avatar === 'object' ? URL.createObjectURL(editUserData?.avatar) : `${FILE_PATH_PROFILE_URL}/${editUserData?.avatar}`) : ''}
                      style={{
                        margin: '10px',
                        width: '80px',
                        height: '80px'
                      }}
                    />
                    <EditIcon
                      style={{
                        color: 'black',
                        position: 'absolute',
                        bottom: 20,
                        right: 19,
                        transform: 'translate(50%, 50%)',
                        zIndex: 1,
                        backgroundColor: 'whitesmoke',
                        borderRadius: '50%',
                        padding: '5px'
                      }}
                    />
                  </div>
                  <input onChange={imageChangeHandler} style={{display: 'none'}} accept="image/*" id="contained-button-file" type="file" />
                </label>
              </IconButton>
              <Typography py={2} fontSize={'16px'}>
                {profileData?.email}
              </Typography>
              <TextInput value={editUserData?.name} label={translate('Name')} onChange={handleInputChange} autoComplete="off" labelVariant="body2" />
              <TextInput type="text" value={editUserData?.mobile_number} maxLength={CONSTANTS.MOBILE_NUMBER_MAX_LENGTH} label={translate('Phone Number')} placeholder={translate('Phone Number')} onChange={handlePhoneInputChange} autoComplete="off" labelVariant="body2" />
              <Grid mb={2}>
                <Typography textAlign="left" variant="body2">
                  {translate('BIRTHDAY')}
                </Typography>
                <DatePicker open={dateOpen} setOpen={() => setDateOpen(true)} onClose={() => setDateOpen(false)} name="birthday" value={editUserData?.birthday ? moment(editUserData?.birthday) : ''} onChange={date => setEditUserData(prev => ({...prev, birthday: moment(date).format('YYYY-MM-DD')}))} maxDate={moment(new Date()).subtract(18, 'year')} />
              </Grid>
              <Grid item xs={12} sx={{height: '55px'}}>
                {error && <Alert severity="error">{error}</Alert>}
              </Grid>
            </FormWrapper>
          </>
        )}
      </Box>
    </UserContainer>
  );
};

export default EditProfile;
