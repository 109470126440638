import React, {useEffect, useState} from 'react';
import {Box, IconButton, Typography} from '@mui/material';
import useTranslations from '../../../hooks/useTranslation';
import AddIcon from '@mui/icons-material/Add';
import Modal from '../../../components/common/Modal';
import DeleteAvailableBookComponent from './DeleteAvailableBookComponent';
import AddUpdateBookComponent from './AddUpdateBookComponent';
import {addBookApi, deleteBookApi, getAllAvailableBooks, getAllPublishedBooks, updateBookApi, updatePublishedBooksApi} from '../../../api';
import useAuthStore from '../../../store/authStore';
import Loader from '../../../components/common/Loader';
import NoDataFoundMessage from '../../../components/common/NoDataFoundMessage';
import {validateWebURL} from '../../../utils';
import {deletesIcon, editsIcon} from '../../../utils/svg';
import IconContainer from '../../../components/common/IconContainer';

const AvailableBooksTabComponent = ({role, selectedTab2}) => {
  const {translate} = useTranslations();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const {selectedOrganization} = useAuthStore(state => state);
  const [allSdBooks, setAllSdBooks] = useState([]);
  const [allOrganizationBooks, setAllOrganizationBooks] = useState([]);
  const [allSDPublishedBooks, setAllSDPublishedBooks] = useState([]);
  const [addAndEditBookData, setAddAndEditBookData] = useState({
    book_name: '',
    ignore_weeks: false,
    year: '',
    url: ''
  });
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [addOrUpdateLoader, setAddOrUpdateLoader] = useState(false);
  const [editOrDeleteBookId, setEditOrDeleteBookId] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [uncheckModal, setUncheckModal] = useState({
    isChecked: false,
    value: false,
    book: null
  });

  const getAllSDBooksHandler = async (selectedYear = '') => {
    try {
      setLoader(true);
      const orgId = role === 'ADMIN' ? selectedOrganization.id : '';
      const year = selectedYear === 'All Books' ? '' : selectedYear;
      const response = await getAllAvailableBooks(orgId, year);
      if (response.data.status === 200) {
        setAllSdBooks(response?.data?.data?.allSDBooks || []);
        setAllOrganizationBooks(response?.data?.data?.allOrganizationBooks || []);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const getAllPublishedBooksHandler = async () => {
    try {
      setLoader2(true);
      const orgId = role === 'ADMIN' ? selectedOrganization.id : '';
      const response = await getAllPublishedBooks(orgId);
      if (response.data.status === 200) {
        setAllSDPublishedBooks(response.data.data?.libraryBooks || []);
      }
      setLoader2(false);
    } catch (error) {
      setLoader2(false);
    }
  };

  const addBookHandler = async () => {
    try {
      setAddOrUpdateLoader(true);
      const data = {
        ...addAndEditBookData,
        ...(role === 'ADMIN' && {
          organization_id: selectedOrganization.id
        })
      };
      const response = await addBookApi(data);
      if (response.data?.status === 200) {
        setModalOpen(false);
        setAddAndEditBookData({
          book_name: '',
          ignore_weeks: false,
          year: '',
          url: ''
        });
        getAllSDBooksHandler();
      }
      setAddOrUpdateLoader(false);
    } catch (error) {
      setAddOrUpdateLoader(false);
    }
  };

  const editBookHandler = async () => {
    try {
      setAddOrUpdateLoader(true);
      const response = await updateBookApi(editOrDeleteBookId, addAndEditBookData);
      if (response.data?.status === 200) {
        setModalOpen(false);
        setAddAndEditBookData({
          book_name: '',
          ignore_weeks: false,
          year: '',
          url: ''
        });
        setEditOrDeleteBookId(null);
        getAllSDBooksHandler();
      }
      setAddOrUpdateLoader(false);
    } catch (error) {
      setAddOrUpdateLoader(false);
    }
  };

  const deleteBookHandler = async () => {
    try {
      setAddOrUpdateLoader(true);
      const res = await deleteBookApi(editOrDeleteBookId);
      if (res.data.status === 200) {
        if (res?.data?.data?.is_in_use) {
          setModalOpen(true);
          setModalType('message_delete');
        }
      }
      if (!res?.data?.data?.is_in_use) {
        setModalOpen(false);
        getAllSDBooksHandler(selectedTab2);
        getAllPublishedBooksHandler();
        setAddAndEditBookData({
          book_name: '',
          ignore_weeks: false,
          year: '',
          url: ''
        });
      }
      setEditOrDeleteBookId(null);
      setIsChecked(false);
      setAddOrUpdateLoader(false);
    } catch (error) {
      setAddOrUpdateLoader(false);
    }
  };

  const handleModalCheck = () => {
    setUncheckModal({
      isChecked: false,
      value: false,
      book: null
    });
    setIsChecked(false);
    setModalOpen(false);
    if (modalType === 'delete') {
      getAllSDBooksHandler(selectedTab2);
      getAllPublishedBooksHandler();
    }
    if (modalType === 'message') {
      getAllSDBooksHandler(selectedTab2);
    }
  };

  const handleCheckBook = async (e, book, type = '') => {
    const value = e.target.checked;
    try {
      let data;
      if (type === 'all') {
        data = {
          allBooks: true,
          ...(role === 'ADMIN' && {
            organization_id: selectedOrganization.id
          })
        };
      } else {
        data = {
          is_published: value,
          ...(role === 'ADMIN' && {
            organization_id: selectedOrganization.id
          })
        };
      }
      setAllSdBooks(prevState => prevState.map(item => (item.id === book.id ? {...item, is_published: value} : item)));
      setAllOrganizationBooks(prevState => prevState.map(item => (item.id === book.id ? {...item, is_published: value} : item)));
      const res = await updatePublishedBooksApi(book.id, data);
      if (res.data.status === 200) {
        if (res?.data?.data?.is_in_use) {
          setModalOpen(true);
          setModalType('message');
        }
      }
      if (!res?.data?.data?.is_in_use) {
        getAllSDBooksHandler(selectedTab2);
        getAllPublishedBooksHandler();
        handleModalCheck();
      }
      setAddOrUpdateLoader(false);
    } catch (error) {
      setAddOrUpdateLoader(false);
    }
  };

  const handleIconClick = (type, book) => {
    setModalType(type);
    setModalOpen(true);
    setEditOrDeleteBookId(book.id);
    setAddAndEditBookData({
      book_name: book.book_name,
      ignore_weeks: book.ignore_weeks,
      year: book.year,
      url: book.url
    });
  };

  useEffect(() => {
    getAllPublishedBooksHandler();
  }, []);

  useEffect(() => {
    getAllSDBooksHandler(selectedTab2);
  }, [selectedTab2]);
  return (
    <Box height={'80vh'}>
      <Box display={'flex'} justifyContent={'space-between'} gap={2} height={'100%'}>
        <Box width={'45%'} height={'100%'} bgcolor={'#9e0101'} borderRadius={2} overflow="auto">
          <Typography variant="h5" fontWeight={'bold'} textAlign={'left'} m={2}>
            {role === 'SUPERADMIN' ? translate('SD Published Library') : translate('Approved Library')}
          </Typography>
          <Box my={2}>
            {loader2 ? (
              <Loader />
            ) : allSDPublishedBooks.length === 0 ? (
              <NoDataFoundMessage message={translate('No Books Available')} color="white" />
            ) : (
              allSDPublishedBooks.map((book, index) => (
                <Typography variant="h6" textAlign={'left'} mx={2} key={index}>
                  {book?.book_name}
                  {book?.year && ` (${book?.year})`}
                </Typography>
              ))
            )}
          </Box>
        </Box>
        <Box width={'55%'} height={'100%'}>
          <Box display={'flex'} flexDirection={'column'} gap={2} sx={{overflowY: 'hidden'}} height={'100%'}>
            <Box height={role === 'ADMIN' ? '60%' : '100%'} border={'1px solid #9e0101'} borderRadius={2} sx={{overflowY: 'auto'}}>
              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={2}>
                <Box display={'flex'} flexDirection={'column'}>
                  <Typography color={'#000000'} variant="h5" fontWeight={'bold'} textAlign={'left'}>
                    {translate('SD Tested Books')}
                  </Typography>
                  <Box
                    onClick={() => handleCheckBook({target: {checked: true}}, {id: 1}, 'all')}
                    sx={{
                      bgcolor: '#9e0101',
                      color: 'white',
                      borderRadius: 12,
                      cursor: 'pointer',
                      padding: '2px 10px'
                    }}
                  >
                    {translate('Add All SD Books to Library')}
                  </Box>
                </Box>
                {role === 'SUPERADMIN' && (
                  <Box
                    onClick={() => {
                      setModalType('add');
                      setModalOpen(true);
                    }}
                    sx={{
                      height: '30px',
                      color: 'black',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 12,
                      cursor: 'pointer'
                    }}
                  >
                    <AddIcon sx={{color: '#9e0101'}} /> {translate('Add Book')}
                  </Box>
                )}
              </Box>
              <Box sx={{px: 2}}>
                {loader ? (
                  <Loader color="#9e0101" />
                ) : allSdBooks.length === 0 ? (
                  <NoDataFoundMessage message={translate('No Books Available')} />
                ) : (
                  allSdBooks.map((book, index) => (
                    <Box key={index} display="flex" justifyContent={'space-between'} alignItems="center" gap={2}>
                      <Box display="flex" alignItems="center" gap={2}>
                        <input
                          type="checkbox"
                          checked={book.is_published}
                          onChange={e => {
                            handleCheckBook(e, book);
                          }}
                          style={{
                            width: '20px',
                            height: '20px'
                          }}
                        />

                        <Typography color={'#000000'} variant="h6">
                          {book?.book_name}
                        </Typography>
                      </Box>
                      {role === 'SUPERADMIN' && (
                        <Box display="flex" gap={1}>
                          <IconButton onClick={() => handleIconClick('edit', book)}>
                            <IconContainer icon={editsIcon} width={20} height={20} />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              handleIconClick('delete', book);
                            }}
                          >
                            <IconContainer icon={deletesIcon} width={20} height={20} />
                          </IconButton>
                        </Box>
                      )}
                    </Box>
                  ))
                )}
              </Box>
            </Box>
            {role === 'ADMIN' && (
              <Box height={'40%'} border={'1px solid #9e0101'} borderRadius={2} sx={{overflowY: 'auto'}}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={2}>
                  <Typography color={'#000000'} variant="h5" fontWeight={'bold'} textAlign={'left'}>
                    {translate('Our Custom Books')}
                  </Typography>
                  <Box
                    onClick={() => {
                      setModalType('add');
                      setModalOpen(true);
                    }}
                    sx={{
                      height: '30px',
                      color: 'black',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 12,
                      cursor: 'pointer'
                    }}
                  >
                    <AddIcon sx={{color: '#9e0101'}} /> {translate('Add Book')}
                  </Box>
                </Box>
                <Box sx={{px: 2}}>
                  {loader ? (
                    <Loader color="#9e0101" />
                  ) : allOrganizationBooks.length === 0 ? (
                    <NoDataFoundMessage message={translate('No Books Available')} />
                  ) : (
                    allOrganizationBooks.map((book, index) => (
                      <Box key={index} display="flex" alignItems="center" justifyContent="space-between">
                        <Box display="flex" alignItems="center" gap={2}>
                          <input
                            type="checkbox"
                            checked={book.is_published}
                            onChange={e => {
                              handleCheckBook(e, book);
                            }}
                            style={{
                              width: '20px',
                              height: '20px'
                            }}
                          />
                          <Typography color={'#000000'} variant="h6">
                            {book.book_name}
                          </Typography>
                        </Box>
                        <Box display="flex" gap={1}>
                          <IconButton onClick={() => handleIconClick('edit', book)}>
                            <IconContainer icon={editsIcon} width={20} height={20} />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              handleIconClick('delete', book);
                            }}
                          >
                            <IconContainer icon={deletesIcon} width={20} height={20} />
                          </IconButton>
                        </Box>
                      </Box>
                    ))
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Modal
        open={modalOpen}
        setOpen={setModalOpen}
        title={translate(modalType === 'add' ? translate('Add Book') : modalType === 'edit' ? translate('Edit Book') : modalType === 'message' ? translate('Message') : modalType === 'message_delete' ? translate('Message') : translate('Delete Book'))}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        buttons={[
          {
            name: translate('Cancel'),
            onClick: () => {
              handleModalCheck();
              setModalOpen(false);
              setAddAndEditBookData({
                book_name: '',
                ignore_weeks: false,
                year: '',
                url: ''
              });
            },
            buttonBgColor: '#ffffff',
            buttonTextColor: 'black',
            buttonHoverColor: '#f5f5f5',
            type: 'outlined'
          },
          {
            name: modalType === 'delete' ? translate('Delete') : modalType === 'check' ? translate('Remove') : translate('Save'),
            buttonBgColor: '#d32f2f',
            disabled: modalType === 'delete' ? !isChecked : modalType === 'check' ? false : !addAndEditBookData.book_name || !addAndEditBookData.year || !validateWebURL(addAndEditBookData.url),
            buttonTextColor: '#ffffff',
            buttonHoverColor: '#b71c1c',
            loading: addOrUpdateLoader,
            hide: modalType === 'message' || modalType === 'message_delete',
            onClick: () => {
              if (modalType === 'delete') {
                deleteBookHandler();
              } else {
                if (modalType === 'check') {
                  handleCheckBook(uncheckModal.value, uncheckModal.book, '');
                } else if (modalType === 'add') {
                  addBookHandler();
                } else {
                  editBookHandler();
                }
              }
            }
          }
        ]}
      >
        {modalType === 'delete' ? <DeleteAvailableBookComponent setIsChecked={setIsChecked} isChecked={isChecked} /> : modalType === 'check' ? <p>{translate('Removing a book from the published library will remove it from all reading plans where it is included.')}</p> : modalType === 'message' ? <p>{translate("Currently, this book is being used in some of the organization's groups for outside reading. Please reach out to the group leaders to unpublish this book from outside reading before unpublishing it from your published library.")}</p> : modalType === 'message_delete' ? <p>{translate("Currently, this book is being used in some of the organization's groups for outside reading. Please reach out to the group leaders to remove this book from outside reading before removing it from your published library.")}</p> : <AddUpdateBookComponent bookId={editOrDeleteBookId} bookData={addAndEditBookData} setBookData={setAddAndEditBookData} />}
      </Modal>
    </Box>
  );
};

export default AvailableBooksTabComponent;
