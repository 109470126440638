import React from 'react';

const IconContainer = ({icon, width, height, marginRight, marginLeft, color, fontWeight, onClick}) => {
  return (
    <div
      dangerouslySetInnerHTML={{__html: icon(color)}}
      onClick={onClick}
      style={{
        width: width,
        height: height,
        marginRight: marginRight,
        marginLeft: marginLeft,
        display: 'flex',
        alignItems: 'center',
        maxWidth: '100%',
        maxHeight: '100%',
        overflow: 'hidden',
        fontWeight: fontWeight
      }}
    />
  );
};

export default IconContainer;
