import React, {useEffect, useState} from 'react';
import CustomButton from '../../common/Button';
import CssBaseline from '@mui/material/CssBaseline';
import {Box} from '@mui/material';
import {useParams} from 'react-router-dom';
import {getMeetings} from '../../../api';
import useTranslations from '../../../hooks/useTranslation';

const PageOne = ({nextpage, setPage, user}) => {
  const {translate} = useTranslations();
  const params = useParams();
  const [data, setData] = useState();

  const handleLevelChange = () => {
    if (params?.level === 'D1') {
      setPage(11);
    } else if (params?.level === 'D2') {
      setPage(12);
    } else if (params?.level === 'D3') {
      setPage(13);
    } else {
      setPage(11);
    }
  };

  const fetchLastQuestionOfMeetings = async () => {
    try {
      const response = await getMeetings(user?.group, '', '', 'check');
      setData(response?.data?.data);
    } catch (error) {}
  };
  useEffect(() => {
    if (user) {
      fetchLastQuestionOfMeetings();
    }
  }, [user]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        overflow: 'auto'
      }}
    >
      <CssBaseline />
      <Box mx={2} mt={12}>
        <CustomButton text={translate('Assessment')} onClick={() => nextpage()} />
        <CustomButton disabled={data?.isDisabled} text={translate('They have finished')} onClick={handleLevelChange} />
        <CustomButton text={translate("They're thinking about dropping")} onClick={() => setPage(9)} />
      </Box>
    </div>
  );
};

export default PageOne;
