import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import NameAndAddressCard from './components/NameAndAddressCard';
import OrganizationStatsCard from './components/OrganizationStatsCard';
import OrganizationContactCard from './components/OrganizationContactCard';
import AdministratorsCard from './components/AdministratorsCard';
import LogoCard from './components/LogoCard';
import AdminStructure from './common/AdminStructure';
import useTranslations from '../../hooks/useTranslation';
import GroupTitle from './components/GroupTitle';
import useAuthStore from '../../store/authStore';
import {showOrganizationsApi} from '../../api';
import Loader from '../../components/common/Loader';

const Settings = () => {
  const {translate} = useTranslations();
  const {selectedOrganization} = useAuthStore(state => state);
  const [data, setData] = useState({});
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [adminLoader, setAdminLoader] = useState(false);

  const fetchOrganizationData = async () => {
    if (selectedOrganization?.id) {
      setLoading(true);
      try {
        const res = await showOrganizationsApi(selectedOrganization.id, '');
        if (res?.data.status === 200) {
          setData(res?.data?.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const fetchAdmins = async () => {
    if (selectedOrganization?.id) {
      setAdminLoader(true);
      try {
        const res = await showOrganizationsApi(selectedOrganization.id, 'admins');
        if (res?.data.status === 200) {
          setAdmins(res?.data?.data);
        }
        setAdminLoader(false);
      } catch (error) {
        setAdminLoader(false);
      }
    }
  };

  useEffect(() => {
    fetchOrganizationData();
    fetchAdmins();
  }, [selectedOrganization]);

  return (
    <>
      <div
        style={{
          backgroundImage: 'none !important',
          backgroundColor: '#f9f9f9',
          height: '100vh',
          width: '100vw',
          overflow: 'hidden'
        }}
      >
        <AdminStructure heading={translate('Settings')}>
          {loading || adminLoader ? (
            <Loader color="#9e0001" />
          ) : (
            <div style={{overflowY: 'auto', height: 'calc(100vh - 64px)'}}>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 2,
                  px: 2,
                  justifyContent: 'space-between',
                  width: '95%',
                  pb: 5
                }}
              >
                <Box width="54%" display="flex" flexDirection="column" gap={2}>
                  <NameAndAddressCard data={data} reloadData={fetchOrganizationData} />
                  <OrganizationContactCard data={data} admindata={admins} reloadData={fetchOrganizationData} />
                  <GroupTitle data={data} reloadData={fetchOrganizationData} />
                  <LogoCard />
                </Box>
                <Box width="42%" display="flex" flexDirection="column" gap={2}>
                  <OrganizationStatsCard data={data} reloadData={fetchOrganizationData} />
                  <AdministratorsCard data={data} admindata={admins} reloadData={fetchAdmins} />
                </Box>
              </Box>
            </div>
          )}
        </AdminStructure>
      </div>
    </>
  );
};

export default Settings;
