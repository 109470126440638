import {Checkbox, FormControlLabel, Typography} from '@mui/material';
import React, {useState} from 'react';
import useTranslations from '../../../hooks/useTranslation';

const DeleteReadingPlanComponent = ({isChecked, setIsChecked}) => {
  const {translate} = useTranslations();
  const handleCheckboxChange = event => {
    setIsChecked(event.target.checked);
  };

  return (
    <>
      <Typography sx={{mb: 2, color: '#000000'}}>{translate('Are you certain that you want to delete this books? All information will be removed from the system and the reading plan will be removed. This action cannot be undone!')}</Typography>
      <FormControlLabel
        sx={{'& .MuiFormControlLabel-label': {color: '#000000', fontSize: '15px', fontWeight: 550}, mb: -1}}
        control={
          <Checkbox
            checked={isChecked}
            onChange={handleCheckboxChange}
            sx={{
              color: '#000000',
              '&.Mui-checked': {
                color: '#000000'
              }
            }}
          />
        }
        label={translate('I am sure that I want to delete this reading plan.')}
      />
    </>
  );
};

export default DeleteReadingPlanComponent;
