import React, { useState, useEffect } from 'react';
import FormWrapper from '../../common/StyledFormWrapper';
import Alert from '@mui/material/Alert';
import CssBaseline from '@mui/material/CssBaseline';
import { Grid, RadioGroup, Radio, FormControlLabel, Typography, Divider, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import TextInput from '../../common/TextInput';
import { getAssessmentsQuestions, postAssessmentsQuestions, getDiscipleQuestions } from '../../../api';
import { CONSTANTS } from '../../../constants';
import QuestionHeadings from './QuestionHeadings';
import Modal from '../../common/Modal';
import ModalHeader from '../../common/ModalHeader';
import HelpOutline from '@mui/icons-material/HelpOutline';
import { useParams } from 'react-router-dom';
import { hasValueInArrayObjectProp } from '../../../utils';
import useTranslations from '../../../hooks/useTranslation';

const PageTwo = ({ nextpage, user, page, totalPage }) => {
  const {translate} = useTranslations();
  const { userId } = useParams();
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [text, setText] = useState();
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen3, setModalOpen3] = useState(false);
  const [preloadClick, setPreloadClick] = useState(false);
  const [doneClick, setDoneClick] = useState(false);
  const [preloadedAnswer, setPreloadedAnswer] = useState([]);
  const answersData = {
    7: [
      {
        "id": 1,
        "options": [
          { value: "Introvert", label: translate("Introvert") },
          { value: "Extrovert", label: translate("Extrovert") },
          { value: "Ambivert", label: translate("Ambivert") }
        ]
      },
      {
        "id": 2,
        "options": [
          { value: "Go", label: translate("Go") },
          { value: "Send", label: translate("Send") }
        ]
      },
      {
        "id": 3,
        "options": [
          { value: "Lead", label: translate("Lead") },
          { value: "Follow", label: translate("Follow") }
        ]
      },
      {
        "id": 4,
        "options": [
          { value: "Tell", label: translate("Tell") },
          { value: "Listen", label: translate("Listen") }
        ]
      },
      {
        "id": 5,
        "options": [
          { value: "Visual", label: translate("Visual") },
          { value: "Auditory", label: translate("Auditory") },
          { value: "Both", label: translate("Both") }
        ]
      },
      {
        "id": 6,
        "options": [
          { value: "Emotional", label: translate("Emotional") },
          { value: "Engineer", label: translate("Engineer") },
          { value: "Activist", label: translate("Activist") }
        ]
      }
    ]
  };
  const fetchQuestions = async () => {
    try {
      setLoading(true);
      const response = await getAssessmentsQuestions(CONSTANTS.ASSESSMENT_QUESTIONS_IDS.ASSESSMENT_1, user?.id, user?.group);
      if (response?.data?.status === 200) {
        let ques = [];
        response?.data?.data?.forEach(item => {
          ques.push({
            ...item,
            question: translate(item.question), // Translate the question
            answer: item?.answer?.split(',') || ''
          });
        });
        let result = {};
        response?.data?.data?.forEach(item => {
          if (typeof item.answer === 'string' && item.answer.includes(',')) {
            const answers = item.answer.split(',');
            answers.forEach((answer, index) => {
              result[index + 1] = answer;
            });
          }
        });
        const ans = getAnswerById(response?.data?.data, 8);
        setText(ans?ans:'');
        setAnswers(result);
        setQuestions(ques);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const preloadedAnswers = async () => {
    try {
      const response = await getDiscipleQuestions(CONSTANTS.DISCIPLE_JOURNEY_IDS.JOURNEY_2, userId);
      if (response.data?.data) {
        const preloadData = response.data?.data;
        const isValid = hasValueInArrayObjectProp(preloadData, "answers")
        let result = {};
        if (!isValid) {
          setModalOpen2(true);
        } else {
          preloadData?.forEach(item => {
            if (typeof item.answers === 'string' && item.answers.includes(',')) {
              const answers = item.answers.split(',');
              answers.forEach((answer, index) => {
                result[index + 1] = answer;
              });
            }
          });
          const ans = getDisAnswerById(preloadData, 56);
          setText(ans);
          setAnswers(result);
          setPreloadClick(true);
        }
      }
    } catch (error) {

    }
  };

  const handleAnswerChange = (questionId, value) => {
    setError(null);
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: value
    }));
    setPreloadClick(false);
  };

  const submitAnswers = async () => {
    const data = {
      user_id: user?.id,
      group_id: user?.group,
      questions: [
        {
          question_id: '7',
          answer: Object.values(answers).join(',')
        },
        {
          question_id: '8',
          answer: text
        }
      ]
    };

    try {
      setLoading(true);
      const response = await postAssessmentsQuestions(data);
      if (response?.data?.status === 200) {
        // nextpage();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const submitAns = async () => {
    if (Object.keys(answers).length === 6 && text?.trim()) {
      await submitAnswers();
      nextpage();
    } else {
      setError(translate("All fields are required!"));
    }
  };

  const handleSubmit = async (e, accept=false) => {
    if (e) {
      e.preventDefault();
    }
    if (accept) {
      submitAns();
      return;
    }
    if (preloadClick) {
      setModalOpen3(true);
    }
    else {
      submitAns();
    }
  };

  function getAnswerById(array, id) {
    const obj = array.find(item => item.id === id);
    return obj ? obj.answer : null;
  }
  function getDisAnswerById(array, id) {
    const obj = array.find(item => item.id === id);
    return obj ? obj.answers : null;
  }

  useEffect(() => {
    fetchQuestions();
  }, []);

  return (
    <>
      <CssBaseline />
      <Box mt={1} px={1} overflow="auto">
        <FormWrapper
          buttonInputs={[
            {
              text: translate("Preload Disciple's Answers"),
              onClick: () => preloadedAnswers(),
              type: 'button',
              loading: loading
            },
            {
              text: translate('Continue'),
              onClick: e => handleSubmit(e),
              type: 'submit',
              loading: loading
            }
          ]}
          // headingText={`Assessment ${page} of ${totalPage}`}
          loading={loading}
        >
          <QuestionHeadings heading1={`${translate('ASSESSMENT')} ${page} ${translate('OF')} ${totalPage}`} heading2={translate('KNOW WHO YOU ARE TALKING TO')} heading3={translate('FIRST MONTH')} isIcon={false} />
          <IconButton onClick={() => setModalOpen(true)} style={{marginBottom: '10px', width: '28px', height: '28px'}}>
            <HelpOutline style={{color: 'white'}} />
          </IconButton>
          <Box my={1} overflow="hidden">
            {questions &&
              questions.map((quest, index) => (
                <Box key={index} mb={2} overflow="hidden">
                  {answersData[quest.id]?.length > 0 && (
                    <Box sx={{textAlign: 'left'}}>
                      <Typography variant="body2" gutterBottom>
                        {quest?.question}
                      </Typography>
                    </Box>
                  )}

                  <Box overflow="hidden">
                    {answersData[quest.id]?.length > 0 && (
                      <Box display="flex" flexDirection="row" flexWrap="wrap" key={index} overflow="hidden">
                        {answersData[quest.id].map((ans, subIndex) => (
                          <Box width={ans.options.length === 2 ? (subIndex === 1 ? '53.33%' : '33.33%') : '100%'} ml={subIndex === 2 && ans.options.length === 2 ? '-20%' : 0} key={subIndex} overflow="hidden">
                            {ans?.options?.length === 2 && subIndex === 1 ? (
                              <Typography textAlign="left" fontStyle="italic">
                                {translate('Pick one from each set')}
                              </Typography>
                            ) : (
                              <Typography>{ans?.options?.length === 2 && '\u00A0'}</Typography>
                            )}
                            <RadioGroup
                              aria-label={`question-${ans.id}`}
                              name={`question-${ans.id}`}
                              style={{
                                display: 'flex',
                                flexDirection: ans.options.length === 2 ? 'column' : 'row'
                              }}
                              value={answers[ans.id] || quest?.answer[subIndex] || ''}
                              onChange={event => handleAnswerChange(ans.id, event.target.value)}
                            >
                              <Grid display="flex" flexDirection={ans.options.length === 2 ? 'column' : 'row'} marginBottom="5px" marginLeft={ans.options.length === 2 ? '0px' : '0px'} container key={subIndex}>
                                {ans.options.map((element, index) => (
                                  <Grid item xs={4} sm={4} md={4} sx={{fontSize: '0.9rem'}} key={index} style={{paddingRight: '100px', borderRight: ans.options.length === 2 && subIndex !== 3 ? '1px solid white' : 0}}>
                                    <Box
                                      sx={{
                                        textAlign: 'left',
                                        fontSize: '0.7rem'
                                      }}
                                      mr={2}
                                    >
                                      <FormControlLabel
                                        value={element.value}
                                        control={<Radio />}
                                        label={element.label}
                                        sx={{
                                          fontSize: '0.9rem',
                                          '& span:last-child': {fontSize: {xs: '0.9rem', sm: '1rem', md: '0.9rem'}}
                                        }}
                                      />
                                    </Box>
                                  </Grid>
                                ))}
                              </Grid>
                            </RadioGroup>
                            <Divider sx={{backgroundColor: 'white'}} />
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>
                </Box>
              ))}

            {questions?.some(obj => obj?.id === 8 || obj?.id === 56) && (
              <>
                <Box sx={{textAlign: 'left'}}>
                  <Typography variant="body2">{translate('WHY DISCIPLESHIP?')}</Typography>
                </Box>
                <TextInput
                  placeholder={translate('Reason for doing discipleship')}
                  value={text}
                  onChange={e => {
                    setError(null);
                    setText(e.target.value);
                    setPreloadClick(false);
                  }}
                  autoComplete="off"
                  margin="normal"
                  error={true}
                  helperText=""
                  labelVariant="body2"
                />
                <p className="bottom-text">{translate('HINT: To have a relationship with god. To get to know Him because they believe in Him. To experience Him')} </p>
              </>
            )}
          </Box>
          <Grid item xs={12} sx={{height: '50px'}}>
            {error && <Alert severity="error">{error}</Alert>}
          </Grid>
        </FormWrapper>
      </Box>
      <Modal open={modalOpen} setOpen={setModalOpen} buttons={[{name: translate('Done'), onClick: () => setModalOpen(false)}]} style={{height: '100%'}} modalbgColor={'white'}>
        <ModalHeader title={translate('How You Think, Learn and Speak')} />
        <Typography variant="h6" fontWeight="bold" mt={1} color="#000">
          {translate('INTROVERT - EXTROVERT - AMBIVERT')}
        </Typography>
        <Typography mt={1} color="#000">
          {translate('Do you recharge in a group (extrovert) or alone (introvert)? Do you process externally (extrovert) ? Do you get peopled out (introvert)? Are you truly a mix of both (ambivert) ?')}
        </Typography>
        <Typography variant="h6" fontWeight="bold" mt={1} color="#000">
          {translate('GO/SEND - LEAD/FOLLOW - TELL/LISTEN')}
        </Typography>
        <Typography mt={1} color="#000">
          {translate('If three people and $3,000 were needed to free nine slaves, would you buy a plane ticket or fund the trip? If an event was going downhill and it seemed like no one knew what to do, would you end up fixing it or supporting the fix? If a question was asked in a group, would you jump in with the answer or wait to see if someone else spoke first?')}
        </Typography>
        <Typography variant="h6" fontWeight="bold" mt={1} color="#000">
          {translate('AUDITORY - VISUAL - BOTH')}
        </Typography>
        <Typography mt={1} color="#000">
          {translate("Can you follow and audio book (auditory learner) ? Do you like pictures, use metaphors, speak word pictures, or have to 'see it' (visual learner) ?")}
        </Typography>
        <Typography variant="h6" fontWeight="bold" mt={1} color="#000">
          {translate('EMOTIONAL - ENGINEER - ACTIVIST')}
        </Typography>
        <Typography mt={1} color="#000">
          {translate("Do you feel life and stories deeply, emotionally? Do you have to figure out the order of things or seem to think linearly? Are you always asking, 'Why would God kill all the people? Why all the rules?'")}
        </Typography>
      </Modal>
      <Modal open={modalOpen2} setOpen={setModalOpen2} buttons={[{name: translate('Close'), onClick: () => setModalOpen2(false)}]} style={{height: '100%'}} backgroundColor="white">
        <ModalHeader title={translate('Answers not exists!')} />
        <Typography variant="h6" fontWeight="bold" mt={1} color="#000">
          {translate('Oops! Disciple Not filled journey answers.')}
        </Typography>
      </Modal>
      <Modal
        open={modalOpen3}
        setOpen={setModalOpen3}
        title={translate('Disciple answers')}
        buttons={[
          {
            name: translate('Continue'),
            onClick: () => {
              setDoneClick(true);
              handleSubmit('', true);
              setModalOpen3(false);
            }
          },
          {
            name: translate('Cancel'),
            onClick: () => {
              setDoneClick(false);
              setModalOpen3(false);
              fetchQuestions();
              setPreloadClick(false);
            }
          }
        ]}
      >
        <Typography sx={{color: 'white'}}> {translate('Are you sure you want to continue with disciple answers as your assessment of disciple?')}</Typography>
      </Modal>
    </>
  );
};

export default PageTwo;