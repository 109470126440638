import React, {useState} from 'react';
import {Box, Grid, Alert, useMediaQuery} from '@mui/material';
import TextInput from '../../components/common/TextInput';
import FormWrapper from '../../components/common/StyledFormWrapper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AdminStructure from './common/AdminStructure';
import useTranslations from '../../hooks/useTranslation';
import PasswordChecklist from 'react-password-checklist';
import {useNavigate} from 'react-router-dom';
import {changePasswordApi} from '../../api';
import AdminTextInput from './components/AdminTextInput';

function AdminChangePassword() {
  const {translate} = useTranslations();
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

  const changePasswordHandler = async () => {
    if (!isValidPassword) {
      setError(translate('Invalid password!'));
      return;
    }
    const data = {
      currentPassword: currentPassword,
      newPassword: password
    };
    try {
      setLoading(true);
      const response = await changePasswordApi(data);
      if (response?.data?.status === 200) {
        navigate('/admin-dashboard/groups');
      } else {
        setError(translate('Failed to change password!'));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleUpdate = e => {
    e.preventDefault();
    if (!currentPassword || !password || !confirmPassword) {
      setError(translate('All fields are required!'));
      return;
    }
    changePasswordHandler();
  };

  return (
    <>
      <div
        style={{
          backgroundImage: 'none !important',
          backgroundColor: '#f9f9f9',
          height: '100vh',
          width: '100vw',
          overflowY: isSmallScreen ? 'auto' : 'hidden'
        }}
      >
        <AdminStructure heading={translate('Change Password')}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt={4}
            sx={{
              height: '85vh',
              overflowY: 'auto',
              paddingX: {
                xs: '20px',
                sm: '30px'
                // md: "30px",
              }
            }}
          >
            <>
              <FormWrapper buttonInputs={[{text: translate('Update'), onClick: handleUpdate}]} loading={loading}>
                <Grid item xs={12} marginBottom={1}>
                  <div className="relative_container">
                    <AdminTextInput
                      autoComplete="off"
                      bottomLabel={translate('Current Password')}
                      value={currentPassword}
                      placeholder={translate('Enter Current Password')}
                      isAllowSpecialChar={true}
                      onChange={e => {
                        setCurrentPassword(e.target.value);
                        setError(null);
                      }}
                      type={showCurrentPassword ? 'text' : 'password'}
                      labelVariant="body2"
                      isRequired
                      fullWidth
                    />
                    {showCurrentPassword ? <VisibilityIcon onClick={() => setShowCurrentPassword(false)} className="eye_admin_icon_change" /> : <VisibilityOffIcon onClick={() => setShowCurrentPassword(true)} className="eye_admin_icon_change" />}
                  </div>
                </Grid>
                <Grid item xs={12} marginBottom={1}>
                  <div className="relative_container">
                    <AdminTextInput
                      autoComplete="off"
                      bottomLabel={translate('New Password')}
                      value={password}
                      placeholder={translate('Enter New Password')}
                      isAllowSpecialChar={true}
                      onChange={e => {
                        setPassword(e.target.value);
                        setError(null);
                      }}
                      type={showPassword ? 'text' : 'password'}
                      labelVariant="body2"
                      isRequired
                      fullWidth
                    />
                    {showPassword ? <VisibilityIcon onClick={() => setShowPassword(false)} className="eye_admin_icon_change" /> : <VisibilityOffIcon onClick={() => setShowPassword(true)} className="eye_admin_icon_change" />}
                  </div>
                </Grid>
                <Grid item xs={12} marginBottom={1}>
                  <div className="relative_container">
                    <AdminTextInput
                      autoComplete="off"
                      bottomLabel={translate('Confirm New Password')}
                      isRequired
                      fullWidth
                      value={confirmPassword}
                      placeholder={translate('Enter Confirm New Password')}
                      isAllowSpecialChar={true}
                      onChange={e => {
                        setConfirmPassword(e.target.value);
                        setError(null);
                      }}
                      type={showConfirmPassword ? 'text' : 'password'}
                      labelVariant="body2"
                    />
                    {showConfirmPassword ? <VisibilityIcon onClick={() => setShowConfirmPassword(false)} className="eye_admin_icon_change" /> : <VisibilityOffIcon onClick={() => setShowConfirmPassword(true)} className="eye_admin_icon_change" />}
                  </div>
                  <PasswordChecklist
                    rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase', 'match']}
                    minLength={8}
                    iconSize={14.4}
                    validColor="black"
                    value={password}
                    valueAgain={confirmPassword}
                    style={{textAlign: 'start', alignItems: 'baseline', fontSize: '14.4px'}}
                    messages={{
                      minLength: translate('Should be mininum 8 characters.'),
                      specialChar: translate('Should contain special character.'),
                      number: translate('Should contain number.'),
                      capital: translate('Should contain uppercase letter.'),
                      lowercase: translate('Should contain lowercase letter.'),
                      match: translate('Password and confirm password should be same.')
                    }}
                    onChange={isValid => setIsValidPassword(isValid)}
                  />
                </Grid>
                <Grid item xs={12} sx={{height: '50px'}}>
                  {error && <Alert severity="error">{error}</Alert>}
                </Grid>
              </FormWrapper>
            </>
          </Box>
        </AdminStructure>
      </div>
    </>
  );
}

export default AdminChangePassword;
