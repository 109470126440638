import {create} from 'zustand';
import {createJSONStorage, persist} from 'zustand/middleware';

const adminStore = (set, get) => ({
  admin: null,
  adminPanelData: null,
  userData: null,
  totalDisciples: 0,

  setTotalDisciples: value => {
    set(state => ({
      ...state,
      totalDisciples: value
    }));
  },

  setAdminData: adminData => {
    set(state => ({
      ...state,
      admin: adminData
    }));
  },
  clearAdminData: () => {
    set(state => ({
      ...state,
      admin: null,
      adminPanelData: null,
      userData: null,
      organizationSelected: ''
    }));
  }
});

const useAdminStore = create(
  persist(adminStore, {
    name: 'admin-storage',
    storage: createJSONStorage(() => localStorage)
  })
);

export default useAdminStore;
