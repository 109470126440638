import {FormControl, Typography} from '@mui/material';
import React from 'react';
import {styled} from '@mui/material/styles';
import useTranslations from '../../../hooks/useTranslation';

const StyledSelect = styled('select')({
  width: '100%',
  padding: '6px',
  margin: '10px 0 20px',
  //   borderRadius: '4px',
  border: '1px solid #000000',
  fontSize: '16px',
  outline: 'none',
  backgroundColor: 'white',
  '&:focus': {
    borderColor: '#d32f2f'
  }
});
const {translate} = useTranslations();

const StyledSelectInput = ({bottomLabel, topLabel, labelTopGap, labelBottomGap, labelVariant, labelColor = '#888888', value, onChange, optionArray = [], placeholder = `${translate('Select')}`, disabled = false, name = '', firstOption, isRequired = false}) => {
  return (
    <FormControl fullWidth>
      {/* <StyledSelect value={value} setValue={setValue} onChange={onChange} optionArray={optionArray} /> */}
      {topLabel && (
        <Typography textAlign="left" color={labelColor} variant={labelVariant} mt={labelTopGap} mb={labelBottomGap}>
          {topLabel}
          {isRequired && <span style={{color: '#d32f2f', marginLeft: '2px'}}>*</span>}
        </Typography>
      )}
      <StyledSelect name={name} disabled={disabled} value={value || ''} onChange={onChange}>
        <option value="" disabled>
          {placeholder}
        </option>
        {firstOption && <option value="">{firstOption}</option>}
        {optionArray.map((option, index) => (
          <option key={option.value || index} value={option.value} disabled={option.disabled} selected={option.selected}>
            {option.label}
          </option>
        ))}
      </StyledSelect>
      {bottomLabel && (
        <Typography textAlign="left" color={labelColor} variant={labelVariant} mt={labelTopGap}>
          {bottomLabel}
          {isRequired && <span style={{color: '#d32f2f', marginLeft: '2px'}}>*</span>}
        </Typography>
      )}
    </FormControl>
  );
};

export default StyledSelectInput;
