import {Box, CircularProgress, useTheme} from '@mui/material';
import React from 'react';

const Loader = ({color = '#FFFFFF'}) => {
  // const theme = useTheme();
  // const loaderColor = theme.palette.loader.primary;
  return (
    <Box width="100%" display="flex" justifyContent="center" alignItems="center" mt={2}>
      <CircularProgress style={{color: color}} />
    </Box>
  );
};

export default Loader;
