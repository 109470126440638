import React, {useState, useEffect} from 'react';
import {Typography, Box, IconButton, Grid, FormControlLabel, Checkbox} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import Modal from '../../../components/common/Modal';
import AdminTextInput from './AdminTextInput';
import useTranslations from '../../../hooks/useTranslation';
import {deleteUser, inviteUser} from '../../../api';
import {validateEmail, validatePhone} from '../../../utils';
import useAuthStore from '../../../store/authStore';
import {deletesIcon} from '../../../utils/svg';
import IconContainer from '../../../components/common/IconContainer';

const AdministratorsCard = ({data, admindata = [], reloadData}) => {
  const {translate} = useTranslations();
  const {selectedOrganization, user} = useAuthStore(state => state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({name: '', phone: '', email: ''});
  const [activeAdmins, setActiveAdmins] = useState([]);
  const [invitedAdmins, setInvitedAdmins] = useState([]);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [modalType, setModalType] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [deletingAdmin, setDeletingAdmin] = useState(null);

  const resetData = () => {
    setFormData({name: '', phone: '', email: ''});
  };

  const handleSubmitClick = async () => {
    if (modalType === 'delete') {
      handleDelete();
      return;
    }
    try {
      setButtonLoader(true);
      const data = {
        first_name: formData?.name?.split(' ')[0] || '',
        last_name: formData?.name?.split(' ')[1] || '',
        email: formData?.email,
        organization_ids: [selectedOrganization.id],
        is_admin: true
      };
      const res = await inviteUser(data);
      if (res?.data?.status === 200) {
        setIsModalOpen(false);
        setFormData({name: '', phone: '', email: ''});
        reloadData();
      }
      setButtonLoader(false);
    } catch (error) {
      setButtonLoader(false);
    }
    setIsModalOpen(false);
    setFormData({name: '', phone: '', email: ''});
    reloadData();
  };

  const handleChange = e => {
    const {name, value} = e.target;
    if (name === 'phone') {
      if (!validatePhone(value)) {
        return;
      }
    }
    setFormData(prev => ({...prev, [name]: value}));
  };

  const handleMenuClick = id => {
    if (id == data?.contact_user_id) {
      setModalType('message');
      setIsModalOpen(true);
    } else {
      setModalType('delete');
      setIsModalOpen(true);
      setDeletingAdmin(id);
    }
  };

  const handleDelete = async () => {
    try {
      setButtonLoader(true);
      const res = await deleteUser(deletingAdmin);
      if (res.data.status === 200) {
        reloadData();
        setModalType('');
        setIsModalOpen(false);
        setIsChecked(false);
      }
      setButtonLoader(false);
    } catch (error) {
      setButtonLoader(false);
    }
  };

  useEffect(() => {
    if (Array.isArray(admindata)) {
      const active = admindata.filter(admin => admin.status === 'ACTIVE');
      const invited = admindata.filter(admin => admin.status === 'INVITED');
      setActiveAdmins(active);
      setInvitedAdmins(invited);
    }
  }, [admindata]);
  return (
    <Box
      sx={{
        width: '100%',
        padding: '16px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        height: 'fit-content',
        backgroundColor: '#ffffff'
      }}
    >
      <Typography
        variant="h6"
        mb={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: '#000'
        }}
      >
        {translate('Administrators')}
        <IconButton
          onClick={() => {
            setIsModalOpen(true);
            setModalType('add');
          }}
          size="small"
          aria-label="add"
        >
          <AddIcon sx={{color: '#d32f2f', fontSize: '14px'}} />
          <span style={{color: '#000', fontSize: '14px', marginLeft: '2px'}}>{translate('Administrator')}</span>
        </IconButton>
      </Typography>

      <Typography variant="h6" mb={1} sx={{textAlign: 'start', color: '#d32f2f', fontWeight: 'bold'}}>
        {translate('Active Administrators')} ({activeAdmins.length})
      </Typography>

      {activeAdmins.map(admin => (
        <Box
          key={admin.id}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            textAlign: 'start',
            color: '#000',
            mt: 1
          }}
        >
          <Typography variant="body1" sx={{color: '#000'}}>
            {admin?.first_name || ''} {admin?.last_name || ''}
          </Typography>
          {admin.id !== user?.id && (
            <IconButton onClick={() => handleMenuClick(admin.id)} size="small" aria-label="menu">
              <IconContainer icon={deletesIcon} width={20} height={20} color={'#000000'} />
            </IconButton>
          )}
        </Box>
      ))}

      <Typography variant="h6" mt={2} mb={1} sx={{textAlign: 'start', color: '#d32f2f', fontWeight: 'bold'}}>
        {translate('Invited Administrators')} ({invitedAdmins.length})
      </Typography>

      {invitedAdmins.map(admin => (
        <Box
          key={admin.id}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            textAlign: 'start',
            color: '#000',
            mt: 1
          }}
        >
          <Typography variant="body1" sx={{color: '#000'}}>
            {admin?.first_name || ''} {admin?.last_name || ''}
          </Typography>
          <IconButton onClick={() => handleMenuClick(admin.id)} size="small" aria-label="menu">
            <IconContainer icon={deletesIcon} width={20} height={20} color={'#000000'} />
          </IconButton>
        </Box>
      ))}

      {/* Modal */}
      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          resetData();
        }}
        title={modalType === 'delete' ? translate('Delete Admin') : modalType === 'message' ? translate('Message') : translate('Add New Administrator')}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        buttons={[
          {
            name: translate('Cancel'),
            onClick: () => {
              setIsModalOpen(false);
              resetData();
            },
            buttonBgColor: '#ffffff',
            buttonTextColor: 'black',
            buttonHoverColor: '#f5f5f5',
            type: 'outlined'
          },
          {
            name: modalType === 'delete' ? translate('Delete') : translate('Save'),
            buttonBgColor: '#d32f2f',
            buttonTextColor: '#ffffff',
            buttonHoverColor: '#b71c1c',
            onClick: handleSubmitClick,
            loading: buttonLoader,
            disabled: buttonLoader || (modalType !== 'delete' ? !formData.name || !validateEmail(formData.email) : !isChecked),
            hide: modalType === 'message'
          }
        ]}
      >
        {modalType === 'delete' ? (
          <>
            <Typography sx={{mb: 2, color: '#000000'}}>{translate('Are you certain that you want to delete this admin? All information will be removed from the system. This action cannot be undone!')}</Typography>
            <FormControlLabel
              sx={{'& .MuiFormControlLabel-label': {color: '#000000', fontSize: '16px', fontWeight: 550}}}
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={e => setIsChecked(e.target.checked)}
                  sx={{
                    color: '#000000',
                    '&.Mui-checked': {
                      color: '#000000'
                    }
                  }}
                />
              }
              label={translate('I am sure that I want to delete this user.')}
            />
          </>
        ) : modalType === 'message' ? (
          <p>{translate('This admin is currently the organization contact for this organization. You cannot delete this admin until you change the organization contact.')}</p>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AdminTextInput fullWidth name="name" bottomLabel={translate('Administrator Name')} placeholder={translate('Enter Administrator Name')} value={formData.name} onChange={handleChange} variant="outlined" labelVariant="body2" isRequired />
            </Grid>
            <Grid item xs={12}>
              <AdminTextInput type="text" fullWidth name="phone" bottomLabel={translate('Phone')} placeholder={translate('Enter Phone Number')} value={formData.phone} onChange={handleChange} variant="outlined" labelVariant="body2" />
            </Grid>
            <Grid item xs={12}>
              <AdminTextInput fullWidth name="email" bottomLabel={translate('Email')} placeholder={translate('Enter Email')} value={formData.email} onChange={handleChange} variant="outlined" labelVariant="body2" isRequired />
            </Grid>
          </Grid>
        )}
      </Modal>
    </Box>
  );
};

export default AdministratorsCard;
