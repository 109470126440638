import {Box, Typography} from '@mui/material';
import sdlogo from '../../images/sdlogo.png';
import useTranslations from '../../hooks/useTranslation';

const ModalHeader = ({color="#000", title}) => {
  const {translate} = useTranslations();
  return (
    <Box display="flex" alignItems="center" mb={2}>
      <img src={sdlogo} width="50" height="50" />
      <Box ml={1}>
      <Typography fontSize={12} variant="sx" color={color}>
          {translate("QUICK HELP")}
        </Typography>
        <Typography dangerouslySetInnerHTML={{__html:title}} variant="h4" fontWeight="bold" color={color}>
          {/* {title}   */}
        </Typography>
      </Box>
    </Box>
  );
};

export default ModalHeader;
