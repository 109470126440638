import React, {useEffect, useState} from 'react';
import {IconButton, Typography, Box, FormControl, MenuItem} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import useTranslations from '../../../hooks/useTranslation';
import AdminTextInput from './AdminTextInput';
import Modal from '../../../components/common/Modal';
import {CONSTANTS} from '../../../constants';
import StyledSelectInput from './StyledSelectInput';
import useAuthStore from '../../../store/authStore';
import {updateOrganization} from '../../../api';
import IconContainer from '../../../components/common/IconContainer';
import {editsIcon} from '../../../utils/svg';

const NameAndAddressCard = ({data, reloadData}) => {
  const {translate} = useTranslations();
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    country: ''
  });
  const [buttonLoader, setButtonLoader] = useState(false);
  const {selectedOrganization, updateOrganizationState} = useAuthStore(state => state);

  const resetData = () => {
    setFormData({name: data.name || '', address: data.address || '', country: data.country || ''});
  };

  const handleChange = e => {
    setFormData(prevData => ({
      ...prevData,
      [e.target.name]: e.target.value
    }));
  };

  const handleSubmitClick = async () => {
    try {
      setButtonLoader(true);
      const res = await updateOrganization(selectedOrganization.id, formData, 'name');
      if (res?.data?.status === 200) {
        setIsEditing(false);
        updateOrganizationState(selectedOrganization.id, '', formData.name);
        reloadData();
      }
      setButtonLoader(false);
    } catch (error) {
      setButtonLoader(false);
    }
  };

  useEffect(() => {
    resetData();
  }, [data]);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          padding: '16px',
          borderRadius: '8px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#ffffff'
        }}
      >
        <Typography variant="h6" mb={2} sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: '#000'}}>
          {translate('Name and Address')}
          <IconButton onClick={() => setIsEditing(true)} size="small" aria-label="edit">
            <IconContainer icon={editsIcon} width={20} height={20} />
          </IconButton>
        </Typography>

        <Box>
          <Typography variant="h5" sx={{fontWeight: 'bold', color: '#000', textAlign: 'start', mb: 1}}>
            {data?.name || translate('N/A')}
          </Typography>

          <Typography variant="body1" sx={{color: '#000', textAlign: 'start', mb: 0.5}}>
            {data?.address || translate('N/A')}
          </Typography>
          <Typography variant="body1" sx={{color: '#000', textAlign: 'start'}}>
            {data?.country || translate('N/A')}
          </Typography>
        </Box>
      </Box>

      <Modal
        open={isEditing}
        onClose={() => {
          setIsEditing(false);
          resetData();
        }}
        title={translate('Name and Address')}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        buttons={[
          {
            name: translate('Cancel'),
            onClick: () => {
              setIsEditing(false);
              resetData();
            },
            buttonBgColor: '#ffffff',
            buttonTextColor: 'black',
            buttonHoverColor: '#f5f5f5',
            type: 'outlined'
          },
          {
            name: translate('Save'),
            buttonBgColor: '#d32f2f',
            buttonTextColor: '#ffffff',
            buttonHoverColor: '#b71c1c',
            onClick: handleSubmitClick,
            loading: buttonLoader,
            disabled: buttonLoader || !(formData.name.trim() && formData.address.trim() && formData.country)
          }
        ]}
      >
        <FormControl fullWidth sx={{marginTop: '12px'}}>
          <AdminTextInput bottomLabel={translate('Church Name')} placeholder={translate('Enter Church Name')} labelVariant="body2" value={formData.name} onChange={e => handleChange({target: {name: 'name', value: e.target.value}})} isRequired />
        </FormControl>
        <FormControl fullWidth sx={{marginTop: '6px'}}>
          <AdminTextInput bottomLabel={translate('Address')} placeholder={translate('Enter Address')} labelVariant="body2" value={formData.address} onChange={e => handleChange({target: {name: 'address', value: e.target.value}})} isRequired />
        </FormControl>
        <StyledSelectInput
          name="country"
          value={formData.country}
          onChange={e => handleChange({target: {name: 'country', value: e.target.value}})}
          fullWidth
          variant="outlined"
          isRequired
          optionArray={CONSTANTS.COUNTRIES.map(country => ({
            label: country,
            value: country
          }))}
          bottomLabel={translate('Country')}
          labelTopGap={-2.5}
        >
          {CONSTANTS.COUNTRIES.map(country => (
            <MenuItem key={country} value={country}>
              {country}
            </MenuItem>
          ))}
        </StyledSelectInput>
      </Modal>
    </>
  );
};

export default NameAndAddressCard;
