import {Box, Typography, Grid, Alert, IconButton} from '@mui/material';
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import Modal from '../../common/Modal';
import {getAllPublishedBooks, getAllReadingPlansBooks, getLeaderOutsideReadingApi, updateLeaderOutsideReadingApi, updateOutsideReadingPlanApi} from '../../../api';
import SelectInput from '../../common/SelectInput';
import DeleteIcon from '@mui/icons-material/Delete';
import FormWrapper from '../../common/StyledFormWrapper';
import useLeaderStore from '../../../store/leaderStore';
import Divide from '../../common/Divide';
import Loader from '../../common/Loader';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import {getValueFromBooks} from '../../../utils';
import Button from '../../common/Button';
import {CONSTANTS} from '../../../constants/index';
import useTranslations from '../../../hooks/useTranslation';
import {Add, Download} from '@mui/icons-material';
import AdminTextInput from '../../../pages/admin/components/AdminTextInput';
import useAuthStore from '../../../store/authStore';

const OutsideReadingPlan = () => {
  const {translate} = useTranslations();
  const location = useLocation();
  const {meetings, group} = useLeaderStore(state => state);
  const {selectedOrganization} = useAuthStore(state => state);
  const groupId = location?.search.split('=')?.[1];
  const [outsideData, setOutsideData] = useState([]);
  const [customBookList, setCustomBookList] = useState([]);
  const [error, setError] = useState(null);
  const [startOutsideReadingWeek, setStartOutsideReadingWeek] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allReadingPlans, setAllReadingPlans] = useState([]);
  const [selectedReadingPlan, setSelectedReadingPlan] = useState(group?.reading_plan_id ?? null);
  const [readingPlanInfo, setReadingPlanInfo] = useState({});
  const [remainingWeeks, setRemainingWeeks] = useState(0);
  const navigate = useNavigate();

  const handleModal = (type, book) => {
    if (type === 'open') {
      setSelectedBook(book);
      setIsModalOpen(true);
    } else {
      setSelectedBook(null);
      setIsModalOpen(false);
    }
  };
  const handleConfirmDelete = () => {
    if (selectedBook) {
      let newOutsideData = outsideData.filter(item => item.sequence_number !== selectedBook.sequence_number);
      if (newOutsideData.length === 0) return setError(translate('Reading plan cannot be empty.'));
      newOutsideData = newOutsideData.map((item, index) => {
        return {...item, sequence_number: index + 1};
      });
      setOutsideData(newOutsideData);
      handleModal('close');
    }
  };
  const getOutsideReadingBooksHandler = async id => {
    try {
      setLoading(true);
      const res = await getAllReadingPlansBooks(id);
      if (res?.data?.status === 200) {
        setOutsideData(res?.data?.data?.data);
        setReadingPlanInfo(res?.data?.data?.readingPlanInfo);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const handleBookSelect = (value, index, weeks, book_id) => {
    const newOutsideData = outsideData.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          customBook: value === 'Use My Own Custom Book',
          book_name: value,
          weeks_to_read: weeks || '',
          customBookName: '',
          book_id: book_id
        };
      }
      return item;
    });
    setOutsideData(newOutsideData);
  };
  const handleBookChange = (value, index, type) => {
    const newOutsideData = outsideData.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          [type]: value
        };
      }
      return item;
    });
    setOutsideData(newOutsideData);
  };
  const hasDuplicateBookNames = data => {
    const bookNames = data.map(item => (item.customBook ? item.customBookName : item.book_name));
    const uniqueBookNames = new Set(bookNames);
    return uniqueBookNames.size !== bookNames.length;
  };
  const updateOutsideCustomPlan = async () => {
    let outData = [];
    if (hasDuplicateBookNames(outsideData)) {
      setError(translate('Duplicate book names found. Please ensure all book names are unique.'));
      return;
    }
    outData = outsideData.map(item => {
      if (item.customBook) {
        return {
          name: item?.customBookName,
          book_id: null,
          weeks_to_read: item?.weeks_to_read,
          sequence_number: item?.sequence_number,
          start_week: item.start_week
          // end_week: null,
        };
      } else {
        return {
          name: item?.book_name,
          book_id: item.book_id,
          weeks_to_read: item?.weeks_to_read,
          start_week: item.start_week,
          sequence_number: item?.sequence_number
          // end_week: item.end_week,
        };
      }
    });
    try {
      setLoading(true);
      const res = await updateLeaderOutsideReadingApi(groupId, readingPlanInfo.id, outData);
      if (res?.data?.status === 200) {
        navigate('/leader');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const addBookHandler = index => {
    const maxBooks = CONSTANTS[`${group?.level}_MAX_BOOKS`];
    if (outsideData.length >= maxBooks) {
      setError(translate('You can add a maximum of {maxBooks} books.').replace('{maxBooks}', maxBooks));
      return;
    }
    const newBook = {
      book_name: '',
      weeks_to_read: 1,
      customBook: false,
      start_week: 1,
      end_week: null,
      customBookName: '',
      sequence_number: index + 1
    };
    const updatedData = [...outsideData];
    updatedData.splice(index + 1, 0, newBook);
    const updatedWithSequence = updatedData.map((item, i) => ({
      ...item,
      sequence_number: i + 1
    }));
    setOutsideData(updatedWithSequence);
  };
  const getAllPublishedBooksHandler = async () => {
    try {
      const res = await getAllPublishedBooks(selectedOrganization?.id || '', group.id);
      if (res.data?.status === 200) {
        let useCustomBook = {
          label: translate('Use My Own Custom Book'),
          value: 'Use My Own Custom Book',
          weeks_to_read: 1
        };
        let customBookData = res?.data?.data?.libraryBooks?.map(book => ({
          label: book.book_name,
          value: book.book_id,
          weeks_to_read: book?.weeks_to_read
        }));
        customBookData.push(useCustomBook);
        setCustomBookList(customBookData);
      }
    } catch (error) {}
  };
  const getAllReadingPlanHandler = async () => {
    try {
      setLoading(true);
      const res = await getLeaderOutsideReadingApi(selectedOrganization?.id || '', group.id);
      if (res.data?.status === 200) {
        setAllReadingPlans(
          res.data?.data?.map(item => {
            return {
              label: item?.name,
              value: item?.id
            };
          })
        );
        const planId = res.data?.data?.[0]?.id;
        if (planId && !selectedReadingPlan) {
          setSelectedReadingPlan(planId);
        } else {
          setLoading(false);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllPublishedBooksHandler();
    getAllReadingPlanHandler();
  }, []);

  useEffect(() => {
    if (selectedReadingPlan) {
      getOutsideReadingBooksHandler(selectedReadingPlan);
    }
  }, [selectedReadingPlan]);

  useEffect(() => {
    let remainingWeeks = readingPlanInfo?.meeting_weeks;
    outsideData.forEach(book => {
      if (!book.ignore_weeks) {
        remainingWeeks = remainingWeeks - book.weeks_to_read;
      }
    });
    setRemainingWeeks(Number(remainingWeeks) < 0 ? 0 : remainingWeeks);
  }, [outsideData]);

  return (
    <>
      <FormWrapper
        buttonInputs={[
          {
            text: translate('Save'),
            onClick: updateOutsideCustomPlan,
            loading: moment.utc(meetings.starting_time).isBefore()
          }
        ]}
      >
        <Divide />
        <Box minHeight="fit-content">
          {loading ? (
            <Loader />
          ) : (
            <>
              <Box textAlign="start">
                <Box display="flex" gap={2} alignItems="center" mb={1}>
                  <SelectInput sx={{height: '40px', borderRadius: '6px'}} optionArray={allReadingPlans} value={selectedReadingPlan} setValue={value => setSelectedReadingPlan(value)} />
                  <Typography sx={{fontSize: '13px'}}>{translate('Pick your plan - the adjust weeks to read.')}</Typography>
                </Box>
                <Typography sx={{fontSize: '16px'}}>
                  {translate('Remaining Weeks for Reading: ')}
                  {remainingWeeks}
                </Typography>
                <Box display="flex" gap={2} alignItems="center" justifyContent="space-between" pr={2}>
                  <Typography sx={{fontSize: '11px'}} width="85%" fontStyle="italic">
                    {translate('The remaining weeks of reading is your Total Weeks of Discipleship less all weeks allocated for reading below.')}
                  </Typography>
                  {/* <Box bgcolor={'#ca0400'} width={30} height={30} borderRadius="4px" boxShadow="1px 1px 1px rgba(0, 0, 0, 0.5)" display="flex" alignItems="center" justifyContent="center">
                    <Download style={{color: 'white'}} />
                  </Box> */}
                </Box>
              </Box>
              <Divide />

              <Box>
                <Box px={4} display="flex" justifyContent="space-between" alignItems="center">
                  <Typography sx={{fontSize: '14px'}} textAlign="start">
                    {translate('Book')}
                  </Typography>
                  <Box mr={'15%'}>
                    <Typography sx={{fontSize: '14px'}}>{translate('Weeks')}</Typography>
                    <Typography sx={{fontSize: '14px'}}>{translate('to Read')}</Typography>
                  </Box>
                </Box>

                {outsideData?.map((option, index) => (
                  <Box width="100%" key={index}>
                    <Box width="100%" display="flex" justifyContent="space-between" alignItems="center" gap={1}>
                      <Box width="65%" display="flex" justifyContent="space-between" alignItems="center" gap={1}>
                        <Box width="10%">
                          <Typography sx={{fontSize: '18px', fontWeight: 'normal'}}>{index + 1}.</Typography>
                        </Box>
                        <SelectInput
                          value={option?.book_id}
                          setValue={value => {
                            setError(null);
                            handleBookSelect(getValueFromBooks(customBookList, value, 'label'), index, getValueFromBooks(customBookList, value, 'weeks_to_read'), value);
                          }}
                          formWidth="90%"
                          sx={{height: '40px', borderRadius: '6px'}}
                          optionArray={customBookList}
                        />
                      </Box>
                      <Box width="35%" display="flex" justifyContent="space-between" alignItems="center" gap={1}>
                        <SelectInput
                          formWidth={'50%'}
                          value={option?.weeks_to_read}
                          sx={{height: '40px', borderRadius: '6px'}}
                          setValue={e => {
                            setError(null);
                            handleBookChange(e, index, 'weeks_to_read');
                          }}
                          optionArray={[...Array(48).keys()].map(i => ({
                            label: i + 1,
                            value: i + 1
                          }))}
                        />
                        <Box width="50%" display="flex" justifyContent="space-between" alignItems="center">
                          <IconButton sx={{width: '20px', height: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center'}} onClick={() => handleModal('open', option)}>
                            <DeleteIcon
                              sx={{
                                color: '#ccc'
                              }}
                            />
                          </IconButton>
                          <IconButton sx={{width: '20px', height: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center'}} onClick={() => addBookHandler(index)}>
                            <Add
                              sx={{
                                color: '#ccc'
                              }}
                            />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                    {option.customBook && (
                      <Box width="100%" display="flex" justifyContent="space-between" alignItems="center" gap={1}>
                        <Box width="65%" display="flex" justifyContent="space-between" alignItems="center" gap={1}>
                          <Box width="10%">
                            <Typography visibility="hidden">1.</Typography>
                          </Box>
                          <AdminTextInput
                            height="40px"
                            sx={{height: '20px', border: 'none', borderRadius: '6px', width: '95%', marginLeft: '2px'}}
                            placeholder={translate('My Custom Book')}
                            value={option?.customBookName}
                            onChange={e => {
                              setError(null);
                              handleBookChange(e.target.value, index, 'customBookName');
                            }}
                          />
                        </Box>
                        <Box width="35%"></Box>
                      </Box>
                    )}
                    <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
                      <Box width="60%" display="flex" alignItems="center" gap={1} ml={0.2}>
                        <Box width="10%">
                          <Typography visibility="hidden">1.</Typography>
                        </Box>
                        <SelectInput
                          formWidth={'30%'}
                          value={option?.start_week}
                          sx={{height: '40px', borderRadius: '6px'}}
                          setValue={e => {
                            setError(null);
                            handleBookChange(e, index, 'start_week');
                          }}
                          optionArray={[...Array(48).keys()].map(i => ({
                            label: i + 1,
                            value: i + 1
                          }))}
                        />
                        <Box>
                          <Typography sx={{fontSize: '10px', textAlign: 'left'}}>{translate('Pick Start Week')}</Typography>
                          <Typography sx={{fontSize: '10px', textAlign: 'left'}}>{moment(meetings?.starting_time).add(option?.start_week, 'week').subtract(1, 'week').format(CONSTANTS.DATE_FORMAT_2)}</Typography>
                        </Box>
                      </Box>
                      <Box width="40%">
                        <Typography sx={{fontSize: '10px', textAlign: 'right'}}>
                          {translate('First Review Week - ')}
                          {moment(meetings?.starting_time).add(Number(option?.start_week), 'week').format(CONSTANTS.DATE_FORMAT_3)}
                        </Typography>
                        <Typography sx={{fontSize: '10px', textAlign: 'right'}}>
                          {translate('Last Review Week - ')}
                          {moment(meetings?.starting_time)
                            .add(Number(option?.weeks_to_read) + (Number(option?.start_week) - 1), 'week')
                            .format(CONSTANTS.DATE_FORMAT_3)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </>
          )}
        </Box>
        <Grid item xs={12} sx={{height: '70px'}}>
          {error && <Alert severity="error">{error}</Alert>}
        </Grid>
      </FormWrapper>

      <Modal color="white" open={isModalOpen} onClose={handleModal} title={translate('Confirm Delete')}>
        <Box>
          <Typography variant="body1" mb={2} color="white">
            {translate('Are you sure you want to delete the book')} {selectedBook?.book_name ? `"${selectedBook.book_name}"` : ''}?
          </Typography>
          <Box display="flex" gap={2} justifyContent="flex-end">
            <Button onClick={handleModal} text={translate('Cancel')} />

            <Button onClick={handleConfirmDelete} text={translate('Delete')} />
          </Box>
        </Box>
      </Modal>

      <Modal
        open={modalOpen}
        setOpen={setModalOpen}
        title={translate('My Own Custom Plan')}
        buttons={[
          {
            name: translate('No'),
            onClick: () => {
              setModalOpen(false);
            }
          },
          {
            name: translate('Yes'),
            onClick: () => {
              setModalOpen(false);
            }
          }
        ]}
      >
        <Typography sx={{color: 'white'}}>{translate('Do you want to continue with Custom reading plan?')}</Typography>
      </Modal>
    </>
  );
};

export default OutsideReadingPlan;
