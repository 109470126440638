import {Box, FormControlLabel, Radio, RadioGroup} from '@mui/material';
import React from 'react';
import useTranslations from '../../../hooks/useTranslation';
import AdminTextInput from './AdminTextInput';
import StyledSelectInput from './StyledSelectInput';

const EditDuplicateReadingPlanComponent = ({plan, handleChange}) => {
  const {translate} = useTranslations();
  const weeks = Array.from({ length: 7 }, (_, i) => i + 42).map(item => ({ label: item, value: item }));
  return (
    <>
      <AdminTextInput labelColor="black" autoComplete="off" labelVariant="body2" colorBlack={'text-black'} name="name" value={plan?.name} onChange={handleChange} fullWidth mb={0} placeholder={translate('Enter Reading Plan Name')} bottomLabel={translate('Reading Plan Name')} isRequired />
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={2} mt={2}>
        <StyledSelectInput name="meeting_weeks" labelColor='black' labelTopGap={-2.5} bottomLabel={translate('No. of Weeks')} optionArray={weeks} labelId="meeting-weeks" id="weeks-select" value={plan.meeting_weeks} onChange={handleChange} displayEmpty isRequired />
        <RadioGroup
          row
          name="level"
          value={plan?.level}
          sx={{
            width: '100%',
            display: 'flex',
            flexWrap: 'nowrap',
            marginTop: -4,
            color: '#d32f2f !important',
            '& .MuiFormControlLabel-label': {
              color: '#d32f2f !important',
              fontWeight: '800',
              fontSize: '1rem'
            }
          }}
          onChange={handleChange}
        >
          <FormControlLabel
            value="D1"
            control={
              <Radio
                sx={{
                  color: '#d32f2f !important',
                  '&.Mui-checked': {
                    color: '#d32f2f !important'
                  }
                }}
              />
            }
            label={translate('D1')}
          />
          <FormControlLabel
            value="D2"
            control={
              <Radio
                sx={{
                  color: '#d32f2f !important',
                  '&.Mui-checked': {
                    color: '#d32f2f !important'
                  }
                }}
              />
            }
            label={translate('D2')}
          />
          <FormControlLabel
            value="D3"
            control={
              <Radio
                sx={{
                  color: '#d32f2f !important',
                  '&.Mui-checked': {
                    color: '#d32f2f !important'
                  }
                }}
              />
            }
            label={translate('D3')}
          />
        </RadioGroup>
      </Box>
    </>
  );
};

export default EditDuplicateReadingPlanComponent;
