import React, {useState} from 'react';
import {Box} from '@mui/material';

function TabSwitcher({children, tab1Text, tab2Text, selectedTab, setSelectedTab, switcher, selectedTab2}) {
  return (
    <Box>
      <Box display={'flex'} alignItems={'center'} gap={2} mb={2}>
        <Box width={'50%'} display={'flex'} alignItems={'center'} gap={2}>
          <Box onClick={() => setSelectedTab(0)} color={selectedTab === 0 ? 'white' : '#000000'} bgcolor={selectedTab === 0 ? '#9e0101' : '#FFFFFF'} p={0.5} borderRadius={12} px={5} sx={{cursor: 'pointer', fontWeight: 'bold', border: '1px solid', borderColor: selectedTab === 0 ? '#9e0101' : '#000000'}}>
            {tab1Text}
          </Box>
          <Box onClick={() => setSelectedTab(1)} color={selectedTab === 1 ? 'white' : '#000000'} bgcolor={selectedTab === 1 ? '#9e0101' : '#FFFFFF'} p={0.5} borderRadius={12} px={5} sx={{cursor: 'pointer', fontWeight: 'bold', border: '1px solid', borderColor: selectedTab === 1 ? '#9e0101' : '#000000'}}>
            {tab2Text}
          </Box>
        </Box>
        {selectedTab === 1 && (
          <Box width={'60%'} display={'flex'} alignItems={'center'} gap={2}>
            {switcher &&
              switcher.map((item, index) => {
                return (
                  <Box key={index} onClick={item.onClick} color={selectedTab2 === item?.title ? 'white' : '#000000'} bgcolor={selectedTab2 === item?.title ? '#9e0101' : '#FFFFFF'} p={0.5} borderRadius={12} px={5} sx={{cursor: 'pointer', fontWeight: 'bold', border: '1px solid', borderColor: selectedTab2 === item?.title ? '#9e0101' : '#CCCCCC'}}>
                    {item.title}
                  </Box>
                );
              })}
          </Box>
        )}
      </Box>
      <Box mb={2}>{children}</Box>
    </Box>
  );
}

export default TabSwitcher;
