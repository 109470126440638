import {FormControlLabel, Checkbox, RadioGroup, Typography} from '@mui/material';
import React from 'react';
import useTranslations from '../../../hooks/useTranslation';

const DeleteAvailableBookComponent = ({setIsChecked, isChecked}) => {
  const {translate} = useTranslations();

  const handleCheckboxChange = event => {
    setIsChecked(event.target.checked);
  };

  return (
    <>
      <Typography sx={{mb: 1, fontSize: '15px',color: '#000000'}}>{translate('Are you certain that you want to delete this books? All information will be removed from the system and the book will be removed from all reading plans. This action cannot be undone!')}</Typography>
      <FormControlLabel
        sx={{'& .MuiFormControlLabel-label': {color: '#000000', fontSize: '15px', fontWeight: 550}}}
        control={
          <Checkbox
            checked={isChecked}
            onChange={handleCheckboxChange}
            sx={{
              color: '#000000',
              '&.Mui-checked': {
                color: '#000000'
              }
            }}
          />
        }
        label={translate('I am sure that I want to delete this book.')}
      />
    </>
  );
};

export default DeleteAvailableBookComponent;
