import React from 'react';
import {Outlet} from 'react-router-dom';
import useAuthStore from '../store/authStore';

const PublicRoutes = () => {
  const {user: userData, token: isLogin} = useAuthStore();

  if (isLogin && userData?.user_role === 'SUPERADMIN') {
    window.location.replace('/super-admin/dashboard');
  }
  if (isLogin && userData?.user_role === 'ADMIN') {
    window.location.replace('/admin-dashboard');
  }
  if (isLogin && userData?.user_role === 'LEADER') {
    if (!userData?.address && !userData?.experience) {
      window.location.replace('/journey');
    } else {
      window.location.replace('/leader');
    }
  }
  if (isLogin && userData?.user_role === 'DISCIPLE') {
    if (!userData?.address && !userData?.experience) {
      window.location.replace('/journey');
    } else {
      window.location.replace('/disciple');
    }
  }
  return <Outlet />;
};

export default PublicRoutes;
