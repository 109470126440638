import React, {useEffect, useState} from 'react';
import {Typography, FormControl, Radio, RadioGroup, FormControlLabel, Grid, Avatar, Box, CircularProgress} from '@mui/material';
import Modal from '../../../components/common/Modal';
import DatePicker from '../../../components/common/DatePicker';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import {BASE_URL, CONSTANTS} from '../../../constants';
import useTranslations from '../../../hooks/useTranslation';
import AdminTextInput from '../components/AdminTextInput';
import StyledSelectInput from '../components/StyledSelectInput';
import {getAdminLeadersGroupApi, getAllReadingPlansApi, updateGroupApi} from '../../../api';
import useAuthStore from '../../../store/authStore';
import TimePickerComponent from '../../../components/common/TimePicker';
import {disableDatesByWeekDay, getTimeZoneCode, isEmptyObjectValues, isValidFile} from '../../../utils';
import toast from 'react-hot-toast';

const AddGroupModal = ({isEdit, open, setOpen, getData, group}) => {
  const {translate} = useTranslations();
  const {selectedOrganization, token} = useAuthStore(state => state);
  const [existingLeader, setExistingLeader] = useState([]);
  const [readingPlan, setReadingPlan] = useState([]);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [buttonLoader2, setButtonLoader2] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [groupData, setGroupData] = useState({
    organization_id: selectedOrganization.id,
    name: group?.name || '',
    leader: group?.leader || '',
    avatar: group?.avatar || '',
    meeting_day: group?.meeting_day || 'monday',
    meeting_time: moment(group?.meeting_time) || moment(),
    starting_time: group?.starting_time ? moment(group?.starting_time) : null,
    meeting_place: group?.meeting_place || '',
    meeting_weeks: group?.meeting_weeks || 48,
    timezone: group?.timezone || getTimeZoneCode(),
    gender: group?.gender || '',
    level: group?.level || '',
    reading_plan_id: group?.reading_plan_id || ''
  });
  const dayOfWeek = [
    {label: translate('Monday'), value: 'monday'},
    {label: translate('Tuesday'), value: 'tuesday'},
    {label: translate('Wednesday'), value: 'wednesday'},
    {label: translate('Thursday'), value: 'thursday'},
    {label: translate('Friday'), value: 'friday'},
    {label: translate('Saturday'), value: 'saturday'},
    {label: translate('Sunday'), value: 'sunday'}
  ];
  const [dateOpen, setDateOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const resetData = () => {
    setGroupData({
      organization_id: selectedOrganization.id,
      name: '',
      leader: '',
      avatar: '',
      meeting_day: 'monday',
      meeting_time: moment(),
      starting_time: '',
      meeting_place: '',
      meeting_weeks: 48,
      timezone: getTimeZoneCode(),
      gender: '',
      level: '',
      reading_plan_id: ''
    });
  };

  const handleEdit = async () => {
    try {
      let groupObject = groupData;
      delete groupObject['avatar'];
      groupObject['starting_time'] = moment(groupObject['starting_time']).format('YYYY-MM-DD');
      setButtonLoader(true);
      const formData = new FormData();
      Object.keys(groupData).forEach(key => {
        formData.append(key, groupData[key]);
      });
      const requestOptions = {
        method: 'PATCH',
        headers: {
          Authorization: token
        },
        body: formData
      };
      const res = await fetch(`${BASE_URL}/group/${group.group_id}?is_leader_update=`, requestOptions);
      if (res.ok) {
        toast.success(CONSTANTS.MESSAGES.GROUP_UPDATED);
        resetData();
        getData('reload');
      }
      setModalOpen(false);
      setOpen(false);
      setButtonLoader(false);
      if (getData) {
        getData();
      }
    } catch (error) {
      toast.error(error?.message || CONSTANTS.MESSAGES.SOMETHING_WRONG);
      setButtonLoader(false);
    }
  };

  const handleSave = async () => {
    if (isEdit) {
      handleEdit();
      return;
    }
    let groupObject = groupData;
    delete groupObject['avatar'];
    groupObject['starting_time'] = moment(groupObject['starting_time']).format('YYYY-MM-DD');
    try {
      const formData = new FormData();
      Object.keys(groupData).forEach(key => {
        formData.append(key, groupData[key]);
      });
      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: token
        },
        body: formData
      };
      setButtonLoader(true);
      const res = await fetch(`${BASE_URL}/group?accept=all`, requestOptions);
      if (res.ok) {
        toast.success(CONSTANTS.MESSAGES.GROUP_CREATED);
        setOpen(false);
        resetData();
      }
      setButtonLoader(false);
      if (getData) {
        getData();
      }
    } catch (error) {
      setButtonLoader(false);
      toast.error(error?.message || CONSTANTS.MESSAGES.SOMETHING_WRONG);
    }
  };

  const imageChangeHandler = e => {
    const file = e.target.files[0];
    if (file.size >= CONSTANTS.MAX_IMAGE_FILE_SIZE) {
      toast.error(translate('File is too large (not more than 10 mb)'));
      return;
    }
    if (isValidFile('image', file)) {
      setGroupData(prev => ({...prev, avatar: e.target.files[0]}));
    } else {
      toast.error(translate('Only image file allowed!'));
      return;
    }
  };

  const handleChangeInput = (name, value) => {
    setGroupData(prev => {
      return {
        ...prev,
        [name]: value,
        ...(name === 'meeting_day' && {
          starting_time: null
        })
      };
    });
  };

  const getAllLeadersHandler = async () => {
    try {
      const res = await getAdminLeadersGroupApi(selectedOrganization.id);
      if (res.data.status === 200) {
        const leaders = res?.data?.data?.map(leader => {
          return {
            label: leader.name,
            value: leader.id
          };
        });
        setExistingLeader(leaders);
      }
    } catch (error) {}
  };

  const getAllReadingPlanHandler = async () => {
    try {
      const res = await getAllReadingPlansApi(selectedOrganization.id, 'group');
      if (res?.data?.status === 200) {
        const readingPlans = res?.data?.data?.allCustomPublishedPlans?.map(readingPlan => {
          return {
            label: readingPlan.name,
            value: readingPlan.id
          };
        });
        setReadingPlan(readingPlans);
      }
    } catch (error) {}
  };

  const handleGroupStatusUpdate = async () => {
    if (group.status !== 'COMPLETED') {
      try {
        setButtonLoader2(true);
        const res = await updateGroupApi(group?.group_id, {isMarkGroupAsCompleted: true});
        setButtonLoader2(false);
        if (res.data.status === 200) {
          resetData();
          setIsChecked(false);
        }
        setModalOpen(false);
        setOpen(false);
        setIsModalOpen(false);
        if (getData) {
          getData('reload');
        }
      } catch (error) {
        setButtonLoader2(false);
        setModalOpen(false);
      }
    }
  };

  useEffect(() => {
    getAllLeadersHandler();
    getAllReadingPlanHandler();
  }, []);

  useEffect(() => {
    setGroupData({
      organization_id: selectedOrganization.id,
      name: group?.name || '',
      leader: group?.leader || '',
      avatar: group?.avatar || '',
      meeting_day: group?.meeting_day || 'monday',
      meeting_time: moment(group?.meeting_time) || moment(),
      starting_time: group?.starting_time ? moment(group?.starting_time) : '',
      meeting_place: group?.meeting_place || '',
      meeting_weeks: group?.meeting_weeks || 48,
      timezone: group?.timezone || getTimeZoneCode(),
      gender: group?.gender || '',
      level: group?.level || '',
      reading_plan_id: group?.reading_plan_id || ''
    });
  }, [group]);

  return (
    <>
      <Modal
        open={open}
        setOpen={() => setOpen(false)}
        title={isEdit ? translate('Edit Group') : translate('Add Group')}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        buttons={[
          {
            name: translate('Cancel'),
            onClick: () => {
              setOpen(false);
              resetData();
            },
            buttonBgColor: '#ffffff',
            buttonTextColor: 'black',
            buttonHoverColor: '#f5f5f5',
            type: 'outlined'
          },
          {
            name: translate('Save'),
            loading: buttonLoader,
            onClick: handleSave,
            buttonBgColor: '#d32f2f',
            buttonTextColor: '#ffffff',
            buttonHoverColor: '#b71c1c',
            disabled: group?.status === 'COMPLETED' || buttonLoader || (isEdit ? isEmptyObjectValues({name: groupData?.name, leader: groupData?.leader}) : isEmptyObjectValues(groupData, ['avatar']))
          }
        ]}
      >
        {/* do not remove this code will uncomment later if needed */}
        {/* <FormControl fullWidth sx={{mb: 2, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <label style={{cursor: 'pointer'}} htmlFor="contained-button-file">
          <Box
            sx={{
              display: 'inline-block',
              position: 'relative'
            }}
          >
            <Avatar
              src={URL.createObjectURL(groupData.avatar)}
              sx={{
                width: 60,
                height: 60
              }}
            />
            <EditIcon
              sx={{
                color: 'black',
                position: 'absolute',
                bottom: 0,
                right: 0,
                transform: 'translate(25%, 25%)', // Adjust this to position the icon better if needed
                zIndex: 1,
                backgroundColor: 'whitesmoke',
                borderRadius: '50%',
                padding: '2px'
              }}
            />
          </Box>
          <input onChange={imageChangeHandler} style={{display: 'none'}} accept="image/*" id="contained-button-file" type="file" />
        </label>
      </FormControl> */}
        {isEdit && (
          <div style={{display: 'flex', alignItems: 'center', gap: 2, position: 'absolute', top: 20, right: 20}}>
            <input
              checked={isChecked || group?.status === 'COMPLETED'}
              disabled={group?.status === 'COMPLETED'}
              onChange={() => {
                setIsModalOpen(true);
                setIsChecked(true);
              }}
              type="checkbox"
              name="completed"
              id="completed"
              isRequired
            />
            <Typography color="#000">{translate('Mark as Completed')}</Typography>
          </div>
        )}

        <FormControl fullWidth>
          <AdminTextInput disabled={isEdit && group?.status === 'COMPLETED'} autoComplete="off" labelVariant="body2" colorBlack={'text-black'} value={groupData.name} onChange={e => handleChangeInput('name', e.target.value)} fullWidth bottomLabel={translate('Enter group name')} placeholder={translate('Enter group name')} isRequired />
        </FormControl>

        <FormControl fullWidth>
          <StyledSelectInput disabled={isEdit && group?.status === 'COMPLETED'} value={groupData.leader} onChange={e => handleChangeInput('leader', e.target.value)} fullWidth variant="outlined" optionArray={existingLeader} bottomLabel={translate('Select Existing Leader')} labelTopGap={-2.5} isRequired />
        </FormControl>

        <FormControl disabled={group?.status === 'COMPLETED' || (isEdit && groupData.starting_time ? !moment(groupData.starting_time).isAfter() : false)} fullWidth>
          <RadioGroup
            row
            sx={{
              color: '#d32f2f !important',
              '& .MuiFormControlLabel-label': {
                color: '#d32f2f !important',
                fontSize: '16px'
              }
            }}
            value={groupData.level}
            onChange={e => handleChangeInput('level', e.target.value)}
          >
            <FormControlLabel
              value="D1"
              control={
                <Radio
                  sx={{
                    color: '#d32f2f !important',
                    '&.Mui-checked': {
                      color: '#d32f2f !important'
                    }
                  }}
                />
              }
              label={translate('D1')}
            />
            <FormControlLabel
              value="D2"
              control={
                <Radio
                  sx={{
                    color: '#d32f2f !important',
                    '&.Mui-checked': {
                      color: '#d32f2f !important'
                    }
                  }}
                />
              }
              label={translate('D2')}
            />
            <FormControlLabel
              value="D3"
              control={
                <Radio
                  sx={{
                    color: '#d32f2f !important',
                    '&.Mui-checked': {
                      color: '#d32f2f !important'
                    }
                  }}
                />
              }
              label={translate('D3')}
            />
          </RadioGroup>
        </FormControl>

        <FormControl disabled={group?.status === 'COMPLETED' || (isEdit && groupData.starting_time ? !moment(groupData.starting_time).isAfter() : false)} fullWidth>
          <RadioGroup
            row
            sx={{
              color: '#d32f2f !important',
              '& .MuiFormControlLabel-label': {
                color: '#d32f2f !important',
                fontSize: '16px'
              }
            }}
            value={groupData.gender}
            onChange={e => handleChangeInput('gender', e.target.value)}
          >
            <FormControlLabel
              value="CO-ED"
              control={
                <Radio
                  sx={{
                    color: '#d32f2f !important',
                    '&.Mui-checked': {
                      color: '#d32f2f !important'
                    }
                  }}
                />
              }
              label={translate('Co-Ed')}
            />
            <FormControlLabel
              value="ALL GUYS"
              control={
                <Radio
                  sx={{
                    color: '#d32f2f !important',
                    '&.Mui-checked': {
                      color: '#d32f2f !important'
                    }
                  }}
                />
              }
              label={translate('All Guys')}
            />
            <FormControlLabel
              value="ALL GIRLS"
              control={
                <Radio
                  sx={{
                    color: '#d32f2f !important',
                    '&.Mui-checked': {
                      color: '#d32f2f !important'
                    }
                  }}
                />
              }
              label={translate('All Girls')}
            />
          </RadioGroup>
        </FormControl>

        <FormControl fullWidth sx={{marginBottom: '8px'}}>
          <StyledSelectInput disabled={group?.status === 'COMPLETED' || (isEdit && groupData.starting_time ? !moment(groupData.starting_time).isAfter() : false)} value={groupData.reading_plan_id} onChange={e => handleChangeInput('reading_plan_id', e.target.value)} fullWidth variant="outlined" optionArray={readingPlan} bottomLabel={translate('Select Reading Plan')} labelTopGap={-2.5} isRequired />
        </FormControl>

        <FormControl fullWidth>
          <AdminTextInput disabled={group?.status === 'COMPLETED' || (isEdit && groupData.starting_time ? !moment(groupData.starting_time).isAfter() : false)} autoComplete="off" labelVariant="body2" colorBlack={'text-black'} value={groupData.meeting_place} onChange={e => handleChangeInput('meeting_place', e.target.value)} fullWidth bottomLabel={translate('Where will you meet?')} placeholder={translate('Enter Meeting Location')} mb={1} isRequired />
        </FormControl>

        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TimePickerComponent disabled={group?.status === 'COMPLETED' || (isEdit && groupData.starting_time ? !moment(groupData.starting_time).isAfter() : false)} color1="black" color2="white" bottomLabel={translate('Time of Day')} value={moment(groupData.meeting_time)} className="lightTimePicker" onChange={e => handleChangeInput('meeting_time', e)} isRequired />
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <StyledSelectInput disabled={group?.status === 'COMPLETED' || (isEdit && groupData.starting_time ? !moment(groupData.starting_time).isAfter() : false)} value={groupData.meeting_day} onChange={e => handleChangeInput('meeting_day', e.target.value)} displayEmpty fullWidth optionArray={dayOfWeek} variant="outlined" bottomLabel={translate('Day of Week')} labelTopGap={-2.5} isRequired />
            </FormControl>
          </Grid>
        </Grid>

        <FormControl fullWidth sx={{marginBottom: '4px'}}>
          <StyledSelectInput disabled={group?.status === 'COMPLETED' || (isEdit && groupData.starting_time ? !moment(groupData.starting_time).isAfter() : false)} value={groupData.timezone} onChange={e => handleChangeInput('timezone', e.target.value)} fullWidth variant="outlined" optionArray={CONSTANTS.TIMEZONE_OPTIONS} bottomLabel={translate('Meeting Timezone')} labelTopGap={-2.5} isRequired></StyledSelectInput>
        </FormControl>

        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <DatePicker disabled={group?.status === 'COMPLETED' || (isEdit && groupData.starting_time ? !moment(groupData.starting_time).isAfter() : false)} className="lightCustomDatePicker" newStyle={{border: '1px solid #000', height: '31px', marginTop: '10px', color: '#000 !important'}} sx={{borderRadius: '8px', border: '1px solid #ccc !important', '& .MuiInputBase-input': {color: 'black !important'}}} open={dateOpen} value={moment(groupData.starting_time)} onChange={e => handleChangeInput('starting_time', e)} setOpen={() => setDateOpen(true)} onClose={() => setDateOpen(false)} minDate={moment().subtract(1, 'year')} showDaysOutsideCurrentMonth shouldDisableDate={date => disableDatesByWeekDay(groupData.meeting_day, date)} isRequired />
              <Typography variant="body2" sx={{color: '#000000 !important'}}>
                {translate('Date You Will Start')}
                <span style={{color: '#d32f2f', marginLeft: '2px'}}>*</span>
              </Typography>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <StyledSelectInput disabled={group?.status === 'COMPLETED' || (isEdit && groupData.starting_time ? !moment(groupData.starting_time).isAfter() : false)} value={groupData.meeting_weeks} onChange={e => handleChangeInput('meeting_weeks', e.target.value)} optionArray={CONSTANTS.ALL_WEEKS} displayEmpty fullWidth variant="outlined" bottomLabel={translate('Number of Weeks')} labelTopGap={-2.5} isRequired />
            </FormControl>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={modalOpen}
        setOpen={() => setModalOpen(false)}
        title={translate('Change Meeting Day of Future Meetings')}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        buttons={[
          {name: translate('Cancel'), onClick: () => setModalOpen(false), buttonBgColor: '#ffffff', buttonTextColor: 'black', buttonHoverColor: '#f5f5f5', type: 'outlined'},
          {
            name: translate('Save'),
            loading: buttonLoader,
            disabled: buttonLoader,
            onClick: handleEdit,
            buttonBgColor: '#d32f2f',
            buttonTextColor: '#ffffff',
            buttonHoverColor: '#b71c1c'
          }
        ]}
      >
        <Typography color="#000">{translate('Please note that changing the meeting day will impact all future scheduled meetings, and any skip weeks will be removed if the changes are saved. Additionally, this change is irreversible once applied.')}</Typography>
      </Modal>

      <Modal
        open={isModalOpen}
        setOpen={() => {
          setIsModalOpen(false);
          setIsChecked(false);
        }}
        title={translate('Confirmation')}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        buttons={[
          {
            name: translate('Cancel'),
            onClick: () => {
              setIsModalOpen(false);
              setIsChecked(false);
            },
            buttonBgColor: '#ffffff',
            buttonTextColor: 'black',
            buttonHoverColor: '#f5f5f5',
            type: 'outlined'
          },
          {
            name: translate('Save'),
            loading: buttonLoader2,
            disabled: buttonLoader2,
            onClick: handleGroupStatusUpdate,
            buttonBgColor: '#d32f2f',
            buttonTextColor: '#ffffff',
            buttonHoverColor: '#b71c1c'
          }
        ]}
      >
        <Typography color="#000">{translate('Are you sure you want to mark this group as completed?')}</Typography>
      </Modal>
    </>
  );
};

export default AddGroupModal;
