import React, {useEffect, useState} from 'react';
import FormWrapper from '../components/common/StyledFormWrapper';
import TextInput from '../components/common/TextInput';
import {Avatar, IconButton, Box, Alert, Grid, Typography} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {getGroupApi} from '../api';
import UserContainer from '../components/common/UserContainer';
import {BASE_URL, CONSTANTS, FILE_PATH_URL} from '../constants';
import {toast} from 'react-hot-toast';
import {capitalizeWords, isValidFile} from '../utils';
import HeaderContainer from '../components/common/HeaderBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import moment from 'moment';
import useAuthStore from '../store/authStore';
import Loader from '../components/common/Loader';
import EditIcon from '@mui/icons-material/Edit';
import useTranslations from '../hooks/useTranslation';
import useLeaderStore from '../store/leaderStore';

const EditGroup = () => {
  const {translate} = useTranslations();
  const {meetings, setEditingMeeting} = useLeaderStore(state => state);
  const params = useParams();
  const [groupData, setGroupData] = useState({
    id: '',
    name: '',
    avatar: ''
  });
  const [editGroupData, setEditGroupData] = useState({
    id: '',
    name: '',
    avatar: ''
  });
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [error, setError] = useState(null);
  const {token} = useAuthStore(state => state);
  const navigate = useNavigate();

  const handleInputChange = e => {
    setError(null);
    if (e.target.value.length <= 64) {
      setEditGroupData(prevData => ({
        ...prevData,
        name: capitalizeWords(e.target.value)
      }));
    }
  };

  const imageChangeHandler = e => {
    let file = e.target.files[0];
    if (file.size >= CONSTANTS.MAX_IMAGE_FILE_SIZE) {
      toast.error(translate('File is too large (not more than 10 mb)'));
      return;
    }
    if (isValidFile('image', file)) {
      setEditGroupData(prevData => ({
        ...prevData,
        avatar: e.target.files[0]
      }));
    } else {
      toast.error(translate('Only image file allowed!'), {
        id: 'toast_message'
      });
    }
  };

  const handleGroupUpdate = async () => {
    if (String(editGroupData?.name).trim().length === 0) {
      setError(translate('All fields are required!'));
      return;
    }
    try {
      const formData = new FormData();
      formData.append('name', editGroupData?.name);
      if (typeof editGroupData?.avatar !== 'string') {
        formData.append('avatar', editGroupData?.avatar);
      }
      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: token
        },
        body: formData
      };

      const response = await fetch(`${BASE_URL}/group?group_id=${editGroupData?.id}`, requestOptions);
      const data = await response.json();
      if (data?.status === 200) {
        toast.success(translate('Group updated successfully'), {
          id: 'toast_message'
        });
        navigate(-1);
      } else {
        toast.error(translate('Group already exist with this name'), {
          id: 'error_message'
        });
      }
      setButtonLoading(false);
    } catch (error) {
      setButtonLoading(false);
    }
  };

  const getGroupHandler = async () => {
    try {
      setLoading(true);
      const response = await getGroupApi(params?.id);
      if (response?.data?.status === 200) {
        setGroupData({
          id: response?.data?.data?.id,
          name: response?.data?.data?.name,
          avatar: `${FILE_PATH_URL}/${response?.data?.data?.avatar}`
        });
        setEditGroupData({
          id: response?.data?.data?.id,
          name: response?.data?.data?.name,
          avatar: `${FILE_PATH_URL}/${response?.data?.data?.avatar}`
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGroupHandler();
  }, []);

  return (
    <UserContainer isShow2={false} onClick={() => navigate('/create-group')} addText={groupData?.group_meeting ? translate('Add Disciple') : translate('Add Meeting')} headingText={groupData?.name ? `${groupData?.name}${translate("'s Group")}` : ''} subHeadingText={groupData?.group_meeting?.meeting_time ? `${moment(new Date(groupData?.group_meeting?.meeting_time)).format('h:mm A')} ${translate('on')} ${groupData?.group_meeting?.meeting_day} ${translate('at')} ${groupData?.group_meeting?.meeting_place}` : ''} filePath={groupData?.avatar} isGroup={false} groupData={groupData} isShow={false}>
      <HeaderContainer mb={3} mx={3} pt={1}>
        <ArrowBackIcon style={{cursor: 'pointer', color: 'white'}} onClick={() => navigate(-1)} />
      </HeaderContainer>
      <Box mt={1} px={3} overflow="auto">
        {loading ? (
          <Loader />
        ) : (
          <>
            <FormWrapper buttonInputs={[{text: translate('Update'), onClick: handleGroupUpdate}]} headingText={translate('Edit Group')} loading={buttonLoading}>
              <IconButton>
                <label style={{display: 'flex', cursor: 'pointer'}} htmlFor="contained-button-file">
                  <Avatar
                    alt={editGroupData?.name}
                    src={editGroupData?.avatar ? (typeof editGroupData?.avatar === 'object' ? URL.createObjectURL(editGroupData?.avatar) : editGroupData?.avatar) : ''}
                    style={{
                      margin: '10px',
                      width: '60px',
                      height: '60px'
                    }}
                  />
                  <EditIcon
                    style={{
                      color: 'black',
                      position: 'absolute',
                      bottom: 20,
                      right: 19,
                      transform: 'translate(50%, 50%)',
                      zIndex: 1,
                      backgroundColor: 'whitesmoke',
                      borderRadius: '50%',
                      padding: '5px'
                    }}
                  />
                  <input onChange={imageChangeHandler} style={{display: 'none'}} accept="image/*" id="contained-button-file" type="file" />
                </label>
              </IconButton>
              <TextInput value={editGroupData?.name} label={translate('Group Name')} onChange={handleInputChange} error={editGroupData?.name && editGroupData?.name.trim().length === 0} helperText={editGroupData?.name && editGroupData?.name.trim().length === 0 ? translate('Please enter valid group name!') : ''} autoComplete="off" labelVariant="body2" />
              <Grid item xs={12} sx={{height: '50px'}}>
                {error && <Alert severity="error">{error}</Alert>}
              </Grid>
            </FormWrapper>
            <Box my={3}>
              {meetings?.group_week_meetings?.map(data => {
                const meetingDate = moment(data?.meeting_date).startOf('day'); // Convert meeting date to start of day for comparison
                return (
                  <Box key={data.id}>
                    {/* Ensure you have a unique key for each child in a list */}
                    <Typography variant="h5" fontWeight="bold" fontSize="large" display="flex" justifyContent="center" alignItems="center">
                      {translate('Reading for ')}
                      {meetingDate.format('MMMM DD')} {translate('Meeting')}
                      <IconButton>
                        {/* Edit Icon - Navigate to edit page */}
                        <EditIcon
                          sx={{color: 'white', ml: 1, cursor: 'pointer'}}
                          fontSize="8px"
                          onClick={e => {
                            e.stopPropagation(); // Prevent IconButton's onClick from triggering
                            setEditingMeeting({
                              ...data,
                              timezone: meetings?.timezone,
                              meeting_weeks: meetings?.meeting_weeks,
                              meeting_date: meetings?.starting_time
                            });
                            navigate(`/leader/meetings?group_id=${params?.id}&type=weekly`);
                          }}
                        />
                      </IconButton>
                    </Typography>
                    <Typography>{data?.week_chapters}</Typography>
                    {data?.outside_reading?.current_book?.split('|')?.map(book => {
                      return (
                        <Box mt={1} mx={2} display="flex" alignItems="center" justifyContent="center">
                          <Typography marginRight={1}>{book}</Typography>
                        </Box>
                      );
                    })}
                  </Box>
                );
              })}
            </Box>
          </>
        )}
      </Box>
    </UserContainer>
  );
};

export default EditGroup;
